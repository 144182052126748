import { Skeleton } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

function UserIds() {
    const [isLoading, setIsLoading] = useState(true);
    const [unavailableNumbers, setUnavailableNumbers] = useState([]);

    useEffect(() => {
        axios
            .get(CONN_KEY + 'Add.php')
            .then((response) => {
                const data = response.data;
                const userIdList = data.map((item) => parseInt(item.userid));

                const maxUserId = Math.max(...userIdList);

                const availableNumbers = [];

                for (let i = 1; i <= maxUserId; i++) {
                    if (!userIdList.includes(i)) {
                        availableNumbers.push(i);
                    }
                }

                setUnavailableNumbers(availableNumbers);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    {unavailableNumbers.length > 0 && (
                        <div id="unavailable-section">
                            <h3>Unavailable Numbers:</h3>
                            <ul className='hgasd'>
                                {unavailableNumbers.map((number) => (
                                    <div key={number}>{number} /</div>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default UserIds;
