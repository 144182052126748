import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Drawer,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
    Upload
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import TooltipAkris from "./Tooltip-akri";
// import ImgCrop from 'antd-img-crop';

const { Option } = Select;
const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;

const StaffPers = () => {
  const [inputs, setInputs] = useState({}); // Move this line here
  const [brands, setBrands] = useState([]);
  const [maxUserId, setMaxUserId] = useState(0);
  const [oneuserid, setuserione] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("permissions") !== null;
    if (!isLoggedIn) {
      navigate("/");
    } else {
      // Fetch permissions from session storage
      const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
      console.log('Stored Permissions:', storedPermissions);
  
      if (storedPermissions && storedPermissions.length > 0) {
        setPermissions(storedPermissions[0]);
      }
  
      // Check if GuidesAzAdd permission is 0 and navigate if true
      if (storedPermissions && storedPermissions[0].StaffAzAdd === 0) {
        navigate("/");
      }
    }
  }, []);
  axios.get(`${CONN_KEY_SENAT}Staff.php?userid=${inputs.userid}`); // Now you can access 'inputs' variable here
  useEffect(() => {
    axios
      .get(CONN_KEY_SENAT + "Staff.php")
      .then((response) => {
        const data = response.data;
        // Find the maximum userid value from the response data
        const maxId = Math.max(...data.map((item) => item.userid));
        const useridone = (maxId + 1).toString().padStart(3, '0');
        setMaxUserId(maxId);
        setBrands(data);
        setuserione(useridone);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm(); // Staff this line to create a form instance

  const onFinish = async (values) => {
    try {
      console.log("Received values of form: ", values);

      const formData = new FormData();
      formData.append("userid", values.userid);
      formData.append("username", values.username);
      formData.append("surename", values.surename);
      formData.append("dadname", values.dadname);
      formData.append("edu", values.edu);
      formData.append("Departament", values.Departament);
      formData.append("recruitment", values.recruitment);
      formData.append("email", values.email);
      formData.append("lokatiogeyd", values.lokatiogeyd);
      formData.append("lokationW", values.lokationW);
      formData.append("lokationLive", values.lokationLive);
      formData.append("Fin", values.Fin);
      formData.append("datebhrd", values.datebhrd);
      formData.append("Catagory", values.Catagory);
      formData.append("Country", values.Country);
      formData.append("Phone", values.Phone);
      formData.append("vezife", values.vezife);
      formData.append("filePP", values.filePP?.[0]?.originFileObj); // Fix file retrieval
      formData.append("fileSH", values.fileSH?.[0]?.originFileObj); // Fix file retrieval

      console.log("formData:", formData);

      const barcodeResponse = await axios.get(
        `${CONN_KEY_SENAT}Staff.php?userid=${values.userid}`
      );
      if (barcodeResponse.data.status === 1) {
        message.error("Bu istifadəçi artıq yükləmisiniz.");
        return;
      }

      const response = await axios.post(`${CONN_KEY_SENAT}Staff.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === 1) {
        message.success("İstifadəçi uğurla əlavə edildi");
        form.resetFields(); // Reset form fields after successful submission
      } else {
        message.error("Bu istifadəçi artıq yükləmisiniz.");
      }
    } catch (error) {
      console.log(error);
      message.error("An error occurred. Please try again later.");
    }
  };
  const onFinishFailed = () => {
    message.error("Müvafiq sahələr boş qala bilməz!");
  };

  const handleChange = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onGenderChange = (value) => {
    // Handle the gender change event here
    console.log("Selected Gender:", value);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const fileList = [];

  return (
    <div className="container">
      {contextHolder}
      <Form
        onFinish={onFinish}
        encType="multipart/form-data" // Staff this attribute
        className="form-Staff container"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinishFailed={onFinishFailed}
      >
        <Card
          title="Yeni STAFF Əlavə Et"
          extra={
            <Button type="default" onClick={showDrawer}>
              Təlimat
            </Button>
          }
        >
          <div style={{ gap: "5" }}>
            <Row gutter={[5]}>
              <Form.Item
                className="ant-form-item"
                label="Vn №"
                name="userid"
                rules={[
                  {
                    required: true,
                    message: "Please select a bələtci!",
                  },
                ]}
                extra={`V/N qeyd olunacaq ${oneuserid.toString()}}`}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  name="userid"
                  onChange={(value) => handleChange("userid", value)}
                  placeholder={`V/N qeyd olunacaq ${oneuserid.toString()}}`}
                  min="1"
                  formatter={(value) => `00${value}`.slice(-3)} // Format the number as "002"
                  parser={(value) => value.replace(/\D/g, "")} // Allow only numeric input
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Soy Ad"
                name="surename"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="surename"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İstifadəçi Adı"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="username"
                  size="large"
                  onChange={handleChange}
                  placeholder="Ad"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Ata Adı"
                name="dadname"
              >
                <Input
                  style={{ width: "100%" }}
                  name="dadname"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İstifadəçi Fin"
                name="Fin"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="Fin"
                  size="large"
                  onChange={handleChange}
                  placeholder="0000000"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Doğum günü"
                name="datebhrd"
              >
                <Input
                  style={{ width: "100%" }}
                  name="datebhrd"
                  size="large"
                  onChange={handleChange}
                  placeholder="00.00.0000"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item" label="Ölkə" name="Country">
                <Input
                  style={{ width: "100%" }}
                  name="Country"
                  size="large"
                  onChange={handleChange}
                  placeholder="Azərbaycan"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Telefon nömrəsi"
                name="Phone"
              >
                <Input
                  style={{ width: "100%" }}
                  name="Phone"
                  size="large"
                  onChange={handleChange}
                  placeholder="+994 00 000 00 00"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Təhsili"
                name="edu"
                size="large"
              >
                <Input
                  style={{ width: "100%" }}
                  name="edu"
                  size="large"
                  onChange={handleChange}
                  placeholder="Ali (Unec)"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İşə qəbul tarixi"
                name="recruitment"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="recruitment"
                  size="large"
                  onChange={handleChange}
                  placeholder="00.00.0000"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Qeydiyat ünvanı"
                name="lokatiogeyd"
                size="large"
              >
                <Input
                  style={{ width: "100%" }}
                  name="lokatiogeyd"
                  size="large"
                  onChange={handleChange}
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Yaşadığı ünvanı"
                name="lokationLive"
                size="large"
              >
                <Input
                  style={{ width: "100%" }}
                  name="lokationLive"
                  size="large"
                  onChange={handleChange}
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Departament"
                name="Departament"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Select
                  placeholder="Service"
                  onChange={onGenderChange}
                  allowClear
                  size="large"
                  name="Departament"
                >
                  <Option value="Service">Service</Option>
                  <Option value="Marketing">Marketing</Option>
                  <Option value="Security">Security</Option>
                  <Option value="Mətbəx">Mətbəx</Option>
                  <Option value="Texnik">Texnik</Option>
                  <Option value="Xadimə">Xadimə</Option>
                  <Option value="Kassir">Kassir</Option>
                  <Option value="Mühasib">Mühasib</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Vəzifə"
                name="vezife"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="vezife"
                  size="large"
                  onChange={handleChange}
                  placeholder="Barmen"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Akkreditasiya"
                name="Catagory"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="Catagory"
                  size="large"
                  onChange={handleChange}
                  placeholder="S3"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İş yeri"
                name="lokationW"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Select
                  placeholder="İş yeri"
                  onChange={onGenderChange}
                  allowClear
                  size="large"
                  name="lokationW"
                >
                  <Option value="Extra">Extra</Option>
                  <Option value="Ellips">Ellips</Option>
                  <Option value="Sahara">Sahara</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="3x4"
                name="filePP"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
                getValueFromEvent={(e) => e.fileList}
              >
                <Upload name="filePP" maxCount={1} listType="picture-card">
                <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Şəxsiyyət vəsiqəsi"
                name="fileSH"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Məlumatı daxil edin",
                  },
                ]}
              >
                <Upload name="fileSH" maxCount={1} listType="picture-card" >
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Row>
            <Space>
              <Button htmlType="reset">Reset</Button>
              <Button htmlType="submit" type="primary" className="ColorMain">
                Əlavə Et
              </Button>
            </Space>
          </div>
        </Card>
      </Form>
      <Drawer

        title="Instruction"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <TooltipAkris />
      </Drawer>
    </div>
  );
};

export default StaffPers;
