import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StaffPersGe from '../components/AddPersonalGe';

const { Content } = Layout;

const AppPresssGe = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();
   
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 10px',
                    }}
                >
                    <div
                       style={{
                            padding: 4,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <StaffPersGe />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};

export default AppPresssGe;
