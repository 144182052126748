import React, { useState } from 'react';
import { Button, Form, Upload, Space, message, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

const CONN_KEY_A = process.env.REACT_APP_NOT_SECRET_CODE_A;

const StoryAdd = () => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append('icon', values.icon[0]?.originFileObj); // Ensure 'icon' matches PHP script
            formData.append('name', values.name[0]?.originFileObj); // Ensure 'name' matches PHP script


            const response = await axios.post(`${CONN_KEY_A}addstory.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                message.success('Story added successfully');
                // Handle any other logic after successful submission
            } else {
                message.error('Error adding story: ' + response.data.message);
            }
        } catch (error) {
            console.error('An error occurred', error);
            message.error('An error occurred. Please try again later.');
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handleCancel = () => setPreviewOpen(false);
    return (
        <Form
            name="validate_other"
            onFinish={onFinish}
            style={{
                maxWidth: 900,
            }}
        >
            <Form.Item
                className="ant-form-item"
                label="Icon"
                name="icon"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                    {
                        required: true,
                        message: 'Please select the icon image',
                    },
                ]}
            >
                <Upload name="icon" maxCount={1} listType="picture-card" onPreview={handlePreview}>
                    <div>
                        <PlusOutlined />
                        <div
                            style={{
                                marginTop: 8,
                            }}
                        >
                            Upload
                        </div>
                    </div>
                </Upload>
            </Form.Item>
            <Form.Item
                className="ant-form-item"
                label="Name"
                name="name"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                    {
                        required: true,
                        message: 'Please select the name image',
                    },
                ]}
            >
                <Upload name="name" maxCount={1} listType="picture-card" onPreview={handlePreview}>
                    <div>
                        <PlusOutlined />
                        <div
                            style={{
                                marginTop: 8,
                            }}
                        >
                            Upload
                        </div>
                    </div>
                </Upload>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button htmlType="reset">Reset</Button>
                </Space>
            </Form.Item>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </Form>
    );
};

export default StoryAdd;
