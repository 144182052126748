import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, message, Image, Spin } from "antd";
import Logo from "../images/logo.png";
import axios from "axios";
import React, { useState } from "react";
const { Content, Footer } = Layout;


const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R?.trim(); // Remove any extra spaces

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setError(false);
      
      // Ensure the URL is formed correctly
      const apiUrl = `${CONN_KEY}login.php`;
  
      const response = await axios.post(apiUrl, {
        username: values.username,
        password: values.password,
      });
  
      setLoading(false);
  
      if (response.data.status === 1) {
        // Save username, level, and id in local storage
        localStorage.setItem("username", values.username);
        localStorage.setItem("_i_", response.data._i_);
        localStorage.setItem("permissions", JSON.stringify(response.data.permissions));
  
        window.location.href = '/Main'; // Redirect to the main page upon successful login
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };
  

  return (
    <Layout className="bg-login">
      <Content className="contetn">
        <div className="login-formloga">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Image
              preview={false}
              src={Logo}
              style={{
                height: "40px",
              }}
            />
            <br />
            <br />
            <Form.Item
              className="ant-form-item"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              className="ant-form-item"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className="ant-form-item">
              <Spin spinning={loading} tip="Loading">
                <div className="content" />
              </Spin>
              {error ? (
                <p className="error-message">Error connecting to the server.</p>
              ) : (
                <Button
                  htmlType="submit"
                  block
                  className="ColorMain login-form-button"
                  style={{ display: loading ? 'none' : 'block' }} // Hide when loading
                >
                  Log in
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer className="login-form-footer"></Footer>
    </Layout>
  );
};

export default Login;
