import { Button, Space, Empty, Skeleton, Table, Input, Modal, Select, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { IdcardOutlined, SearchOutlined, FilePdfOutlined, EditOutlined, StopOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const { Option } = Select;


function GeoMenu() {
    const [pin, setPin] = useState('');
    const [open, setopen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const pinInputRef = useRef();

    const showDeleteConfirm = (record) => {
        setItemToDelete(record);
        setopen(true);
    };
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const staffOutReasonInputRef = useRef(null);
    const [dropdownValuesCatagory, setdropdownValuesCatagory] = useState([]);
    const navigate = useNavigate();
    // const tableClassName = "custom-table";
    const options = [
        {
            value: 'WHISKEY',
        },
        {
            value: 'VODKA',
        },
        {
            value: 'TEQUILA',
        },
        {
            value: 'GIN',
        },
        {
            value: 'COGNAC',
        },
        {
            value: 'CHAMPAGNE',
        },
        {
            value: 'WİNE',
        },
        {
            value: 'VERMUT',
        },
        {
            value: 'APERETIV',
        },
        {
            value: 'LIQUOR',
        },
        {
            value: 'ROM',
        },
        {
            value: 'BEER',
        },
        {
            value: 'SNACKS',
        },
        {
            value: 'TOBACCO',
        },
        {
            value: 'COCKTAILS',
        },
        {
            value: 'SOFT DRINKS',
        },
        {
            value: 'SHISHA',
        },
        {
            value: 'FIREWORKS',
        },
    ];
    const [editedPrice, setEditedPrice] = useState(null);

    const handleEditPrice = (record) => {
        setEditedPrice({ menu_id: record.menu_id, price: record.price });
    };

    const handleSavePrice = async (record) => {
        try {
            // Perform the API call to save the edited price
            const response = await axios.put(`${CONN_KEY}updatemenuGeudata.php`, {
                menu_id: record.menu_id,
                price: editedPrice.price,
            });

            if (response.data.success) {
                // Handle success, e.g., update the table or show a success message
                Modal.success({
                    title: 'Success',
                    content: 'Price updated successfully.',
                });
                // Refresh the data after successful update
                fetchData();
            } else {
                // Show an error message from the API response
                Modal.error({
                    title: 'Error',
                    content: response.data.message || 'Failed to update the price.',
                });
            }
        } catch (error) {
            // Handle error, e.g., show a generic error message
            console.error(error);
            Modal.error({
                title: 'Error',
                content: 'An error occurred while updating the price.',
            });
        } finally {
            // Reset the editedPrice state
            setEditedPrice(null);
        }
    };

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('username') !== null;
        if (!isLoggedIn) {
            navigate('/');
        } else {
            console.log('User is logged in.');
            // console.log('Username:', localStorage.getItem('username'));
            // console.log('Level:', localStorage.getItem('s'));
        }
    }, [navigate]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 15,
        },
    });
    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    const fetchData = () => {
        axios.get(CONN_KEY + 'menuGeudata.php')
            .then(response => {
                const updatedData = response.data.map(item => ({ ...item, key: item.id }));
                setData(updatedData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        surename="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        surename="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        surename="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        surename="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const columnValue = record[dataIndex];
            if (typeof columnValue === 'undefined') {
                return false; // Return false or a default value if the column value is undefined
            }
            return columnValue.toString().toLowerCase().includes(value.toLowerCase());
        },

        onFilterDropdownOpenChange: (open) => {
            if (open) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => {
            if (typeof text === 'undefined') {
                return null; // Return null or a default value if the text is undefined
            }

            const highlightedText = searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );

            return <span>{highlightedText}</span>;
        },

    });
    const renderActions = (text, record) => {
        const menu = (
            <Menu>
                <Menu.Item key="delete" onClick={() => showDeleteConfirm(record)}>
                    <DeleteOutlined /> Delete
                </Menu.Item>
                <Menu.Item key="edit">
                    <Link to={`/UpdatemenuGeo/${record.menu_id}`} state={{ modalopen: true }}>
                        <EditOutlined /> Edit
                    </Link>
                </Menu.Item>
            </Menu>
        );
        return (
            <Space>
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button> <MoreOutlined /></Button>
                </Dropdown>
            </Space>
        );
    };
    const getColumnCatagoryProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {dataIndex === 'Catagory' && (
                    <Select
                        ref={searchInput}
                        placeholder={`Filter by ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(value) => setSelectedKeys(value ? [value] : [])}
                        onPressEnter={confirm}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    >
                        {options.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.value}
                            </Option>
                        ))}
                    </Select>
                )}
                <Space>
                    <Button
                        type="primary"
                        onClick={confirm}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) => {
            return record[dataIndex] === value;
        },
        render: (text) => {
            return <>{text.toUpperCase()}</>;
        },
    });
    const columns = [
        {
            title: 'Catagory',
            dataIndex: 'Catagory',
            width: 150,
            key: '5',
            ...getColumnCatagoryProps('Catagory'),
        },
        {
            title: 'Product',
            dataIndex: 'product',
            width: 150,
            key: '3',
            ...getColumnSearchProps('product'),
        },
        {
            title: 'Price',
            width: 150,
            dataIndex: 'price',
            key: '2',
            ...getColumnSearchProps('price'),
            render: (text, record) => {
                const isEditing = record.menu_id === editedPrice?.menu_id;

                return isEditing ? (
                    <Input

                        value={editedPrice?.price}
                        onChange={(e) => setEditedPrice({ ...editedPrice, price: e.target.value })}
                        onPressEnter={() => handleSavePrice(record)}
                        onBlur={() => handleSavePrice(record)}
                    />
                ) : (
                    <div>
                        {text}
                        <Button
                            type="link"
                            size="small"
                            onClick={() => handleEditPrice(record)}
                        >
                            Edit
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Updated',
            width: 150,
            dataIndex: 'update',
            key: '43',
            sorter: {
                compare: (a, b) => {
                    const updateA = new Date(a.update);
                    const updateB = new Date(b.update);
                    return updateA - updateB;
                },
                multiple: 1,
            },
        },
        {
            title: 'More',
            dataIndex: 'menu_id',
            key: '256321',
            render: renderActions,
            width: 80, // Set minimum width to 100 pixels
        },
    ];
    const handleDelete = async () => {
        try {
            // Perform PIN validation here
            if (pin === '0102') {
                const response = await axios.delete(`${CONN_KEY}menuGeudata.php?menu_id=${itemToDelete.menu_id}`);
                if (response.status === 200 && response.data.code === 200) {
                    // Handle success, e.g., update the table or show a success message
                    Modal.error({
                        title: 'Error',
                        content: 'Incorrect PIN. Item not deleted.',
                    });
                } else {
                    // Show an error message from the API response

                    Modal.success({
                        title: 'Success',
                        content: 'Product deleted successfully.',
                    });
                }
            } else {
                // Incorrect PIN
                Modal.error({
                    title: 'Error',
                    content: 'Incorrect PIN. Item not deleted.',
                });
            }
        } catch (error) {
            // Handle error, e.g., show a generic error message
            console.error(error);
            Modal.error({
                title: 'Error',
                content: 'An error occurred while deleting the product.',
            });
        } finally {
            setopen(false);
            setItemToDelete(null);
            setPin(''); // Reset the PIN input
        }
    };
    const handleCancel = () => {
        setopen(false);
        setItemToDelete(null);
        setPin(''); // Reset the PIN input
    };
    const handleTableChange = (pagination, filters, sorter) => {
        // handle table change event and update the state if necessary
        setTableParams((prevParams) => ({
            ...prevParams,
            pagination,
        }));
    };
    return (
        <>
            <>Menu table Geo</>
            {isLoading ? (
                <Skeleton active />
            ) : data.length > 0 ? (
                <>
                    <Modal
                        title="Confirm Deletion"
                        open={open}
                        onOk={handleDelete}
                        onCancel={handleCancel}
                    >
                        <div>
                            <p>Please enter your PIN to confirm the deletion of the item:</p>
                            <Input
                                ref={pinInputRef}
                                type="password"
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                            />
                        </div>
                    </Modal>
                    <Table
                        columns={columns}
                        sticky
                        dataSource={data}
                        loading={isLoading}
                        onChange={handleTableChange}
                        scroll={{ y: 550, x: 'max-content' }}
                        pagination={{
                            ...tableParams.pagination,
                            showSizeChanger: true,
                            showTotal: (total) => `Total ${total} items`,
                            pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200', '500', '800'],
                        }}
                        summary={() => (
                            <Table.Summary fixed='top'>
                            </Table.Summary>
                        )}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="No Data"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    style={{ marginTop: 50 }}
                                />
                            ),
                        }}
                    />
                </>
            ) : (
                <div>
                    <p>No data found.</p>
                    <Empty />
                    <Button type="primary" className='ColorMain'>
                        <Link to="/add">Add</Link>
                    </Button>
                </div>
            )}
        </>
    );
}

export default GeoMenu;