import { Button, Input, Space } from "antd";
import React, { useState } from "react";
import { RedoOutlined, SaveOutlined, CameraFilled } from "@ant-design/icons"
import cameraIcon from '../images/cameraflip-icon.png';
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";


const videoConstraints = {
  width: 300,
  height: 400,
  facingMode: "user",
};

const Webcamcomp = () => {

  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [facingMode, setFacingMode] = useState("user");
  const [imageName, setImageName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const webcamRef = React.useRef(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const handleRetake = () => {
    setImage(null);
  };

  const handleCameraSwitch = () => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };

  const handleSave = () => {
    if (image) {
      // Convert the image data to a Blob
      const byteCharacters = atob(image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });

      // Create a URL for the Blob
      const imageUrl = URL.createObjectURL(blob);

      // Trigger download by creating an anchor element
      const a = document.createElement("a");
      a.href = imageUrl;

      // Set the default file name based on user input
      const fileName = imageName || "captured_image.jpg";
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the Blob URL
      URL.revokeObjectURL(imageUrl);
    }
  };

  return (
    <div className="webdfS">
      <h1>Take 3x4 Photo</h1>
      <div className="webcam-container">
        <div className="webcam-img">
          {image === null ? (
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Webcam
                audio={false}
                height={400}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={330}
                videoConstraints={{ ...videoConstraints, facingMode }}
              />
            </div>
          ) : (
            <div className="sdghbadf">
              <img src={image} alt="img" />
              <div >
                <br />
                <Space>
                  <Button className="flex items-center" onClick={handleRetake} icon={<RedoOutlined />}>Retake</Button>
                  <Button className="flex items-center" onClick={handleSave} icon={<SaveOutlined />}>Save</Button>
                </Space>
                <br />
                <Input
                  type="text"
                  size="large"
                  placeholder="Enter image name"
                  value={imageName}
                  onChange={(e) => setImageName(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {image === null && (
            <Space>
              <Button onClick={handleCameraSwitch} className="webcam-btn flex gap-1">
                <img src={cameraIcon} style={{ width: '20px', height: '20px' }} alt="llf" />
              </Button>
              <Button type="primary" onClick={handleCapture} icon={<CameraFilled size="middle"/> } className="webcam-btn">
                Capture
              </Button>
            </Space>
          )}
        </div>
      </div>
    </div >
  );
};

export default Webcamcomp;
