import React, { useState } from 'react';
import axios from 'axios';
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const FaceRecognition = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [scannedImages, setScannedImages] = useState([]);

    const handleFileChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (selectedImage) {
            try {
                const formData = new FormData();
                formData.append('file', selectedImage);

                const response = await axios.post(`${CONN_KEY}ScanImages.py`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log('Server response:', response.data);

                if (response.data && response.data.images) {
                    setScannedImages(response.data.images);
                } else {
                    setScannedImages([]);
                }
            } catch (error) {
                console.error('Error scanning images:', error);
                setScannedImages([]);
            }
        }
    };

    return (
        <div>
            <h1>Face Recognition</h1>

            <form onSubmit={handleFormSubmit}>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                <button type="submit">Scan</button>
            </form>

            {scannedImages.length > 0 ? (
                <div>
                    <h2>Scanned Images:</h2>
                    {scannedImages.map((image, index) => (
                        <div key={index}>
                            <h3>Image {index + 1}</h3>
                            <img src={image} alt={`Scanned Image ${index + 1}`} style={{ maxWidth: '300px' }} />
                        </div>
                    ))}
                </div>
            ) : (
                <div>
                    <h2>No scanned images found.</h2>
                </div>
            )}
        </div>
    );
};

export default FaceRecognition;
