import {
    FilePdfOutlined,
    FlagFilled,
    FlagOutlined,
    HolderOutlined,
    MenuOutlined,
    PlusCircleOutlined,
    ProfileOutlined,
    ReadOutlined,
    SettingOutlined,
    SolutionOutlined,
    UserAddOutlined,
    VideoCameraOutlined,
    WomanOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Footers from "../components/Footer";
import Headers from "../components/Header";
import Tabbar from "../components/Tabbar";
import logo from "../images/logo.png";
import "../index.css";

const Example = () => {
    const [permissions, setPermissions] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is logged in (replace with your own logic)
        const isLoggedIn = localStorage.getItem('username') !== null;

        if (!isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        // Fetch permissions from session storage
        const storedPermissions = JSON.parse(localStorage.getItem('permissions'));

        if (storedPermissions && storedPermissions.length > 0) {
            setPermissions(storedPermissions[0]);
        }
    }, []);

    const canSeeSectionsGuidesAz = permissions.GuidesAz === 1;
    const canSeeSectionsGuidesAzAdd = permissions.GuidesAzAdd === 1;
    const canSeeSectionsGuidesGeo = permissions.GuidesGeo === 1;
    const canSeeSectionsStafGeo = permissions.StafGeo === 1;
    const canSeeSectionsStafAZ = permissions.StafAz === 1;
    const canSeeSectionsStafAZAdd = permissions.StafAzAdd === 1;
    const canSeeSectionsMenuAZ = permissions.Menuaz === 1;
    const canSeeSectionsMenuPdfAZ = permissions.MenuPdfAZ === 1;
    const canSeeSectionsMenuazAdd = permissions.MenuazAdd === 1;
    const canSeeSectionsMenuGeo = permissions.MenuGeo === 1;
    const canSeeSectionsUzv = permissions.Abone === 1;
    const canSeeSectionsUzvAboneAdd = permissions.AboneAdd === 1;
    const canSeeSectionsAdmin = permissions.Id === 1;
    const canSeeSectionsMap = permissions.Rezerv === 1;

    return (
        <div className="bg-gradient-to-r from-indigo-100 via-red-100 to-blue-100  " >
            <div className="absolute inset-x-0 top-0 right-0 overflow-hidden  -z-100">
                {/* <img src={bgssd} className="-ml-[39rem] w-[113.125rem] max-w-none" alt="bg" /> */}
            </div>
            <header className="absolute inset-x-0 top-0 z-50 bg-[#f5f5f5]">
                <nav className="flex items-content-center justify-between p-1 lg:px-8" aria-label="Global">
                    <div className="flex flex-row flex-nowrap justify-between items-center">
                        <Link to={"/Main"}>
                            <img
                                className="h-8 w-auto cursor-pointer"
                                src={logo}
                                alt="logo"
                            />
                        </Link>
                        <Headers />
                    </div>
                </nav>
            </header >
            <div className="dark py-10 sm:py-6">
            </div >
            <section className="container bg-[#f5f5f5] mx-auto p-10 md:py-12 px-0 md:p-8 md:px-0">
                <section className="p-5 md:80 grid grid-cols-1 sm:grid-cols-2 0d:grid-cols-2 md:grid-cols-4 2xl:grid-cols-4 gap-5 ite8-start ">
                    {/* Bələdçilər-AZE */}
                    {canSeeSectionsGuidesAz && (
                        <>
                            <Link to="../Tables">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <FlagOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Bələdçilər az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {canSeeSectionsGuidesAzAdd && (
                        <>
                            <Link to="../ADD">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <PlusCircleOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Yeni Bələdçi</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {canSeeSectionsGuidesAz && (
                        <>
                            <Link to="../All">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <FlagFilled style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Bütün beledci az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Staff-az*/}
                    {canSeeSectionsStafAZ && (
                        <>
                            <Link to="/personal">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <SolutionOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Staff Az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {canSeeSectionsStafAZAdd && (
                        <>
                            <Link to="/AddPersonal">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <UserAddOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Yeni Staff Az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Menu-AZ*/}
                    {canSeeSectionsMenuPdfAZ && (
                        <>
                            <Link to="/MenuPdf">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <ProfileOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Menu pdf az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {canSeeSectionsMenuazAdd && (
                        <>
                            <Link to="/menuAdd">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <MenuOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Menu Add az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {canSeeSectionsMenuAZ && (
                        <>
                            <Link to="/Tablemenu">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <ReadOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Menu az</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Bələdçilər-Geo */}
                    {canSeeSectionsGuidesGeo && (
                        <>
                            <Link to="/TableGe">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <FlagOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Guides</h1>
                                </section>
                            </Link>
                            <Link to="/ADDGe">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <PlusCircleOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Add Guides</h1>
                                </section>
                            </Link>
                            <Link to="/All-Ge">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <FlagFilled style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">All Guides</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Staff-Geo*/}
                    {canSeeSectionsStafGeo && (
                        <>
                            <Link to="/personal-ge">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <SolutionOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Staff Geo</h1>
                                </section>
                            </Link>
                            <Link to="/Add-Personal-GE">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <UserAddOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">New Staff Geo</h1>
                                </section>
                            </Link>
                            <Link to="/All-Geo-Staff">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <UserAddOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">All Staff Geo</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Menu-Geo*/}
                    {canSeeSectionsMenuGeo && (
                        <>
                            <Link to="/MenuGeu">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <ProfileOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Menu Pdf Geo</h1>
                                </section>
                            </Link>
                            <Link to="/NewMenuGeo">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <MenuOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Menu Add Geo</h1>
                                </section>
                            </Link>
                            <Link to="/MenugeoTable">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-red-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <ProfileOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5">Menu Geo</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Uzv*/}
                    {canSeeSectionsUzv && (
                        <>
                            <Link to="/üzv">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <UserAddOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5 ">Yeni Üzv</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {canSeeSectionsUzvAboneAdd && (
                        <>
                            <Link to="/üzvTable">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <WomanOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5 ">Üzvlər</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Map rezerv */}
                    {canSeeSectionsMap && (
                        <>
                            <Link to="/mapmain">
                                <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                                    <div className="mx-auto mb-2 inline-block">
                                        <HolderOutlined style={{ fontSize: '30px' }} />
                                    </div>
                                    <h1 className="text-xl my-5 ">Rezervlər</h1>
                                </section>
                            </Link>
                        </>
                    )}
                    {/* Pdf*/}
                    <Link to="/DownloafPdf">
                        <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                            <div className="mx-auto mb-2 inline-block">
                                <FilePdfOutlined style={{ fontSize: '30px' }} />
                            </div>
                            <h1 className="text-xl my-5 ">PDF Yüklə</h1>
                        </section>
                    </Link>
                    <Link to="/Webcam">
                        <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                            <div className="mx-auto mb-2 inline-block">
                                <VideoCameraOutlined style={{ fontSize: '30px' }} />
                            </div>
                            <h1 className="text-xl my-5 ">Camera</h1>
                        </section>
                    </Link>
                    <Link to="/Settings">
                        <section className="p-5 py-8 text-center transform duration-500 shfdjx hover:-translate-y-2 hover:shadow-2xl cursor-pointer border rounded-lg bg-blue-100">
                            <div className="mx-auto mb-2 inline-block">
                                <SettingOutlined style={{ fontSize: '30px' }} />
                            </div>
                            <h1 className="text-xl my-5 ">Setting</h1>
                        </section>
                    </Link>
                </section>
            </section>
            {/* <FaceDetection /> */}
            <Tabbar />
            <Footers />
        </div >
    );
};

export default Example;