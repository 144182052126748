import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, List, Button } from "antd";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const Uplodedimages = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fetchFiles(); // Fetch files when the component mounts
    const refreshInterval = setInterval(fetchFiles, 2000); // Refresh data every 2 seconds

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(refreshInterval);
    };
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${CONN_KEY}uploadimage.php`);
      setFileList(response.data.files);
    } catch (error) {
      console.error("Error fetching files", error);
      messageApi.error("Failed to fetch files");
    }
  };

  const handleDownload = async (fileName) => {
    const downloadUrl = `${CONN_KEY}Images/${fileName}`;

    try {
      const response = await axios.get(downloadUrl, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      messageApi.error("Failed to download file. Please try again later.");
    }
  };

  const handleDelete = async (fileName) => {
    try {
      const response = await axios.delete(`${CONN_KEY}uploadimage.php?file=${fileName}`);
      if (response.data.success) {
        messageApi.success("File deleted successfully");
        // Fetch the updated list of files after deletion
        fetchFiles();
      } else {
        messageApi.error("Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      messageApi.error("Failed to delete file. Please try again later.");
    }
  };

  return (
    <div className="container">
      {contextHolder}
      <h2>Yüklənmiş Fayllar:</h2>
      {fileList && Object.keys(fileList).length > 0 ? (
        <List
          dataSource={Object.keys(fileList)}
          renderItem={(fileId) => (
            <List.Item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href={`${CONN_KEY}Images/${fileList[fileId]}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${CONN_KEY}Images/${fileList[fileId]}`}
                    style={{ width: 150 }}
                    alt="Şəkil"
                  />
                </a>
                <Button type="primary" onClick={() => handleDownload(fileList[fileId])}>
                  Download
                </Button>
                <Button type="danger" onClick={() => handleDelete(fileList[fileId])}>
                  Delete
                </Button>
              </div>
            </List.Item>
          )}
        />
      ) : (
        <p>No files available</p>
      )}
    </div>
  );
};

export default Uplodedimages;
