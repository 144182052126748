import { Layout, theme } from 'antd';
import Footers from '../../components/Footer';
import Headers from '../../components/Header';
import Siders from '../../components/Sider';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Addmmenu from '../../components/MenuGeo/AddMenuGeo';

const { Content } = Layout;

const AddmmenupageGE = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();
    
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 10px',
                    }}
                >
                    <div
                    // className='container'
                        style={{
                            padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                            alignItems:'center'
                        }}
                    >
                        <Addmmenu className="container"/>
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};

export default AddmmenupageGE;
