import { Layout, theme } from 'antd';
import Tables from '../components/Guideaz/table';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabbar from '../components/Tabbar';
const { Content } = Layout;

const Table = () => {

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout dark:bg-slate-800"
                    style={{
                        // padding: '0 10px',
                        // height: '80vh'
                    }}
                >
                    <div
                        className='dark:bg-slate-800'
                    >
                        <h1 className='dark:text-white'>Bələdçilər AZ</h1>
                        {/* <br /> */}
                        <Tables className="dark:bg-slate-800" />
                    </div>
                </Content>
                <Tabbar />
                <Footers />
            </Layout>
        </Layout>
    );
};

export default Table;
