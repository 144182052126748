import { Button, Card, Drawer, Form, Input, InputNumber, message, Row, Space, Upload } from 'antd';
import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { useNavigate } from "react-router-dom";
import Webcamcomp from "../CameraComp";
import UserIdsG from "../NullIDSGirls";
import Uplodedimages from "../uploadedimages";

const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;

const isOlderThan18 = (dateString) => {
  const today = moment();
  const birthDate = moment(dateString, 'DD.MM.YYYY');
  return today.diff(birthDate, 'years') >= 18;
};


const SellingItem = () => {
  const [inputs, setInputs] = useState({});
  const [maxUserId, setMaxUserId] = useState(0);
  const [openCam, setOpenCam] = useState(false);
  const [oneuserid, setOneUserId] = useState(0);
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState({});
  const specificNumbers = [ 202];
  const navigate = useNavigate();
  const [form] = Form.useForm(); // Add this line to create a form instance

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].AboneAdd === 0) {
      navigate("/");
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("userid", values.userid || '');
      formData.append("username", values.username || '');
      formData.append("surename", values.surename || '');
      formData.append("dadname", values.dadname || '');
      formData.append("Fin", values.Fin || '');
      formData.append("datebhrd", values.datebhrd || '');
      formData.append("Country", values.Country || '');
      formData.append("Phone", values.Phone || '');
  
      if (values.filePP && values.filePP[0]) {
        formData.append("filePP", values.filePP[0].originFileObj);
      }
      if (values.fileSH && values.fileSH[0]) {
        formData.append("fileSH", values.fileSH[0].originFileObj);
      }
  
      const response1 = await axios.post(`${CONN_KEY_SENAT}AddGrils.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response1.data.status === 1) {
        message.success(response1.data.message);
        form.resetFields();
      } else {
        message.error(response1.data.message);
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred. Please try again later.");
    }
  };
  
  
  const onFinishFailed = () => {
    message.error('Müvafiq sahələr boş qala bilməz!');
  };

  const handlePermissionCheck = (number) => {
    if (specificNumbers.includes(number)) {
      const password = prompt('This number requires permission. Please enter the password:');
      return password === '0001';
    }
    return true; // Default to true if no specific permission is required
  };

  const handleChange = (fieldName, value) => {
    const permissionGranted = handlePermissionCheck(value); // Check permissions

    if (!permissionGranted) {
      // Handle case where permission is not granted
      console.log(`Permission denied for value ${value}`);
      const password = prompt('This number requires permission. Please enter the password:');
      if (password !== '0001') {
        // Incorrect password, reload the page
        message.error('Incorrect password. Page will refresh.');
        setTimeout(() => {
          window.location.reload();
        }, 1500); // Adjust delay as needed
        return;
      }

      // Proceed with handling the change if password is correct
      console.log(`Field ${fieldName} changed to ${value}`);
    }

    // Proceed with handling the change if permission is granted
    console.log(`Field ${fieldName} changed to ${value}`);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(CONN_KEY_SENAT + 'AddGrils.php')
      .then((response) => {
        const data = response.data;
        const maxId = Math.max(...data.map((item) => item.userid));
        const useridoneValue = maxId + 1;
        setMaxUserId(maxId);
        setOneUserId(useridoneValue);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const showDrawercam = () => {
    setOpenCam(true);
  };

  const onCloseCam = () => {
    setOpenCam(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  return (
    <div className="container">
      <Form
        onFinish={onFinish}
        encType="multipart/form-data" // Add this attribute
        className="form-Add container"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinishFailed={onFinishFailed}
      >
        <Card
          title="Yeni abonəçi"
          extra={
            <Space>
              <Button type="default" onClick={showDrawer}>
                Böş V/N
              </Button>
              <Button
                type="default"
                className="camera-drawer"
                onClick={showDrawercam}
              >
                Camera
              </Button>
            </Space>
          }
        >
          <div style={{ gap: "10" }}>
            <Row gutter={[16, 16]}>
              <Form.Item
                label="User ID"
                name="userid"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a user ID!',
                  },
                ]}
                extra={`Növbəti ID: ${oneuserid}`}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  size="large"
                  placeholder={`Enter user ID (max: ${maxUserId})`}
                  min={1}
                  formatter={(value) => `00${value}`.slice(-4)}
                  parser={(value) => value.replace(/\D/g, "")}
                  onChange={(value) => handleChange("userid", value)}
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Soy ad"
                name="surename"

                rules={[
                  {
                    required: true,
                    message: 'Please select a bələtci!',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="surename"
                  size="large"
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  onChange={handleChange}
                  placeholder="Soy adı"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="İstifadəçi adı"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please select a bələtci!',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="username"
                  size="large"
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  onChange={handleChange}
                  placeholder="Ad"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Ata adı"
                name="dadname"
              >
                <Input
                  style={{ width: '100%' }}
                  name="dadname"
                  size="large"
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="İstifadəçi Fin"
                name="Fin"
                rules={[
                  {
                    required: true,
                    message: 'Please select a bələtci!',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="Fin"
                  size="large"
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value.toUpperCase().replace(/\s/g, ''); // Convert to uppercase and remove spaces
                    setInputs((prevInputs) => ({
                      ...prevInputs,
                      Fin: newValue.replace(/[^A-Z0-9]/g, ''), // Replace non-alphanumeric characters
                    }));
                  }}
                  placeholder="00AABBC"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Doğum günü"
                name="datebhrd"
                rules={[
                  {
                    required: true,
                    message: 'Zəhmət olmasa Doğum gününü qeyd edin',
                  },
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (isOlderThan18(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Yaş 18-dən az olmamalıdır'));
                    },
                  },
                ]}
              >
                <InputMask
                  mask="99.99.9999" // Define the input mask pattern
                  maskChar="0" // Use '0' as the mask character
                  value={inputs.datebhrd} // Provide the input value (optional)
                  onChange={(e) => handleChange("datebhrd", e.target.value)} // Handle change
                >
                  {(inputProps) => (
                    <Input
                      onKeyPress={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault(); // Prevent typing space character
                        }
                      }}
                      {...inputProps}
                      style={{ width: "100%" }}
                      name="datebhrd"
                      size="large"
                      placeholder="DD.MM.YYYY"
                    />
                  )}
                </InputMask>
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Ölkə"
                name="Country"
              >
                <Input
                  style={{ width: '100%' }}
                  name="Country"
                  size="large"
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  onChange={handleChange}
                  placeholder="Azəraycan"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Telefon nömrəsi"
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: 'Zəhmət olmasa Telefon nömrəsi qeyd edin',
                  },
                ]}
              >
                <InputMask
                  mask="999 999 99 99" // Define the input mask
                  maskChar=" " // Use space as the mask character
                  value={""} // Set an empty string or the phone number value
                  onChange={(e) => handleChange("Phone", e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      style={{ width: "100%" }}
                      name="Phone"
                      onKeyPress={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault(); // Prevent typing space character
                        }
                      }}
                      size="large"
                      placeholder="(000) 000 00 00"
                    />
                  )}
                </InputMask>
              </Form.Item>
              {/* <Form.Item
                className='ant-form-item'
                label="WhatsApp nömrəsi"
                name="PhoneWP"
              >
                <InputMask
                  mask="999 999 99 99" // Define the input mask
                  maskChar=" " // Use space as the mask character
                  value={""} // Set an empty string or the PhoneWP number value
                  onChange={(e) => handleChange("PhoneWP", e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      style={{ width: "100%" }}
                      name="PhoneWP"
                      onKeyPress={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault(); // Prevent typing space character
                        }
                      }}
                      size="large"
                      placeholder="(000) 000 00 00"
                    />
                  )}
                </InputMask>
              </Form.Item> */}
              <Form.Item
                className='ant-form-item'
                label="3x4"
                name="filePP"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: 'Zəhmət olmasa 3x4 qeyd edin',
                  },
                ]}

                getValueFromEvent={(e) => e.fileList}
              >
                <Upload
                  name="filePP"
                  maxCount={1}
                  listType="picture"
                >
                  <Button>Choose File</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Şəxsiyyət vəsiqəsi"
                name="fileSH"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: 'Zəhmət olmasa şəxsiyyət qeyd edin',
                  },
                ]}
              >
                <Upload
                  name="fileSH"
                  maxCount={1}
                  listType="picture"
                >
                  <Button>Choose File</Button>
                </Upload>
              </Form.Item>
            </Row>
            <Space>
              <Button htmlType="submit" type="primary" className="ColorMain" >
                Əlavə Et
              </Button>
              <Button htmlType="reset">Reset</Button>
            </Space>
          </div>
        </Card>
      </Form>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <UserIdsG />
      </Drawer>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onCloseCam}
        open={openCam}
      >
        <Webcamcomp />
        <Uplodedimages />
      </Drawer>
    </div >
  );
};

export default SellingItem;
