import { Layout, Avatar, Dropdown, Menu, Button, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './theme.less';

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const { Header } = Layout;

const Headers = ({ props }) => {
  const navigate = useNavigate(); // Initialize navigate ref with useNavigate hook
  const handleLogout = () => {
    // Get the username from local storage
    const username = localStorage.getItem('username');

    // Remove the username and level from local storage
    localStorage.removeItem('username');
    localStorage.removeItem('s');
    localStorage.removeItem('_i_');

    // Send a logout request to logout.php along with the username
    axios.post(CONN_KEY + 'Logout.php', { logout: true, username: username })
      .then(response => {
        console.log(response.data);
        // Optionally, you can redirect the user to the home page after successful logout
        navigate('/');
      })
      .catch(error => {
        console.error('Logout error:', error);
        // Handle any error that might occur during the logout process
      });
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="1">
        <Button type="text" onClick={handleLogout} style={{ color: '#ED3F3F' }}>
          Çıxış
        </Button>
      </Menu.Item>
    </Menu>
  );
  // Retrieve the username from local storage
  const username = localStorage.getItem('username');
  const [clickCount, setClickCount] = useState(0);
  useEffect(() => {
    if (clickCount === 4) {
      // Reset click count after 4 clicks
      setClickCount(0);
    }
  }, [clickCount]);
  return (
    <Dropdown overlay={userMenu}>
      <Avatar
        style={{
          color: '#ffffff',
          marginRight: 20
        }}
      >
        {username ? username.charAt(0) : <UserOutlined />}
      </Avatar>
    </Dropdown>
  );
};

export default Headers;