import { Button, Skeleton, Space, message } from 'antd';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../App.css';
import Block from '../../images/block.png';
const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;
axios.interceptors.request.use((config) => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Headers'] = '*';
    config.headers['Access-Control-Allow-Methods'] = '*';
    return config;
});
function PDFUzv() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { UserId } = useParams();
    const invoiceSectionRef = useRef(null);
    const [permissions, setPermissions] = useState({});
    const navigate = useNavigate();
  
    useEffect(() => {
      // Fetch permissions from session storage
      const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
      console.log('Stored Permissions:', storedPermissions);
  
      if (storedPermissions && storedPermissions.length > 0) {
        setPermissions(storedPermissions[0]);
      }
  
      // Check if GuidesAzAdd permission is 0 and navigate if true
      if (storedPermissions && storedPermissions[0].Abone === 0) {
        navigate("/");
      }
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fakturaUrl = `${CONN_KEY_SENAT}AddGrils.php?userid=${UserId}`;
                // console.log('fakturaUrl:', fakturaUrl);
                const fakturaResponse = await axios.get(fakturaUrl);
                // console.log('fakturaResponse:', fakturaResponse.data);

                const beletcinum = fakturaResponse.data.Beletcinum; // Get the Beletcinum value
                const userDataUrl = `${CONN_KEY_SENAT}AddGrils.php?userid=${beletcinum}`; // Fetch user data based on Beletcinum
                const userDataResponse = await axios.get(userDataUrl);
                // console.log('userDataResponse:', userDataResponse.data);

                const beletcinumUsername = userDataResponse.data.username;
                const beletcinumsurename = userDataResponse.data.surename;
                setData({
                    ...fakturaResponse.data,
                    beletcinum: beletcinum,
                    beletcinumUsername: beletcinumUsername,
                    beletcinumsurename: beletcinumsurename,
                });

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [UserId]);

    const handlePrint = () => {
        if (invoiceSectionRef.current) {
            const printContents = invoiceSectionRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            window.onload = () => {
                window.print();
                document.body.innerHTML = originalContents;
            };
        }
    };

    const handleDownload = async () => {
        if (invoiceSectionRef.current) {
            const originalContents = document.body.innerHTML;
            const images = invoiceSectionRef.current.getElementsByTagName('img');

            const loadImagePromises = Array.from(images).map(async (image) => {
                try {
                    const imageUrl = `https://cors-anywhere.herokuapp.com/${image.src}`; // Add the CORS proxy URL
                    const response = await axios.get(imageUrl, { responseType: 'blob' });
                    const blob = response.data;
                    const reader = new FileReader();

                    const base64Promise = new Promise((resolve, reject) => {
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                    });

                    reader.readAsDataURL(blob);
                    const base64DataUrl = await base64Promise;
                    image.src = base64DataUrl;
                } catch (error) {
                    console.error('Error downloading image:', error);
                }
            });

            try {
                await Promise.all(loadImagePromises);
            } catch (error) {
                console.error('Error loading images:', error);
                document.body.innerHTML = originalContents;
                return;
            }

            const opt = {
                margin: 0,
                filename: `${data.userid}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            };

            html2pdf()
                .set(opt)
                .from(invoiceSectionRef.current)
                .toPdf()
                .output('blob')
                .then((pdfBlob) => {
                    // Create form data and append the PDF file
                    const formData = new FormData();
                    formData.append('pdf', pdfBlob, `${data.userid}.pdf`);

                    // Send the form data via POST request to the server
                    axios.post(`${CONN_KEY_SENAT}OutoPdfGrils.php`, formData)
                        .then((response) => {
                            // Handle the response from the server
                            console.log('Upload successful:', response);
                            document.body.innerHTML = originalContents;
                            // Display success message
                            message.success('PDF uploaded successfully!');
                        })
                        .catch((error) => {
                            console.error('Error uploading PDF:', error);
                            document.body.innerHTML = originalContents;
                            // Display error message
                            message.error('Error uploading PDF. Please try again.');
                        });
                })
                .catch((error) => {
                    console.error('Error generating PDF:', error);
                    document.body.innerHTML = originalContents;
                    // Display error message
                    message.error('Error generating PDF. Please try again.');
                });
        }
    };


    // console.log('data:', data); // Check the data in the console
    console.log('isLoading:', isLoading); // Check the isLoading state in the console

    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space>
                        <Button onClick={handlePrint}>Print</Button>
                        <Button onClick={handleDownload}>Yüklə</Button>
                    </Space>
                    <div className="container">
                        <div id="invoice-section" ref={invoiceSectionRef}>
                            {data.Status === 'Block' && <img className="Block" src={Block} alt="block" />}
                            <div className="GrilsBackpsf page">
                                <div className="hjhfm">
                                    <div className="ttextsd">
                                        <h3 className="hw3e namessd">
                                            {data.username} {data.surename} <br />
                                            {data.dadname}
                                        </h3>
                                        {/* <h3 className="hw3e ssda">{data.vezife}</h3> */}
                                        {/* {data.Beletcinum !== undefined && data.Beletcinum !== 'undefined' && (
                                            <>
                                                <h3 className="hw3e ssda">Vəsiqə №: {data.Beletcinum}</h3>
                                                <h3 className="hw3e ssda">
                                                    {data.beletcinumUsername} {data.beletcinumsurename}
                                                </h3>
                                            </>
                                        )} */}
                                        <h3 className="hw3e">Fin: {data.Fin}</h3>
                                        <h3 className="hw3e">Doğum tarixi: {data.datebhrd}</h3>
                                        <h3 className="hw3e">Vətəndaşlığ: {data.Country}</h3>
                                        <h3 className="hw3e">Əlaqə: {data.Phone}</h3>
                                        <h3 className="hw3e">Vəsiqə №: {data.userid}.pdf</h3>
                                        <h3 className="hw3e">Block Səbəbi: {data.BlockSeb}</h3>
                                        <h3 className="hw3e">Block Tarixi: {data.BlockSebdate}</h3>
                                    </div>
                                    <div className="jshdf">
                                        <img className="imgPPDF light-effect" src={`${CONN_KEY_SENAT}3x4Girl/${data.filePP}`} alt="PP" />
                                    </div>
                                </div>
                                <div className="fdjdx">
                                    <img className="imgLKSH" src={`${CONN_KEY_SENAT}scanGirl/${data.fileSH}`} alt="SH" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PDFUzv;
