import { Layout, Menu, Image } from "antd";
import React, { useState, useEffect } from "react";
import {
    AppstoreAddOutlined,
    AppstoreOutlined,
    FlagFilled,
    FilePdfOutlined,
    SettingOutlined,
    UserAddOutlined,
    SolutionOutlined,
    MenuOutlined,
    VideoCameraOutlined,
    ReadOutlined,
    FileProtectOutlined,
    CoffeeOutlined,
    ProfileOutlined,
    WomanOutlined,
    FlagOutlined,
    UserOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
const Tabbar = () => {
    const [collapsed, setCollapsed] = useState(true);
    const userLevel = localStorage.getItem("s");
    const userName = localStorage.getItem("username");
    const rootSubmenuKeys = ["GuidesAz", "Guides", "Staff", "menuGeo", "menu", "uzv"];

    const items = [
        // Guides AZ
        userLevel ===
            "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq" || //A3
            userLevel ===
            "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C" ||
            userLevel ===
            "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu" ||//A2
            userLevel ===
            "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W" || //A8
            userLevel === "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea" //A5
            ? null
            : getItem("Bələdçilər", "GuidesAz", <FlagFilled />, [
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG"
                    ? getItemWithCondition()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG"
                    ? getItemWithConditionAdd()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG"
                    ? getItemWithCardAll()
                    : null,
            ]),
        // Guides Geo A6
        userLevel ===
            "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG" ||
            userLevel ===
            "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W" || //A8
            userLevel ===
            "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C" ||
            userLevel ===
            "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu" ||//A2
            userLevel === "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea"//A5
            ? null
            : getItem("Guides", "Guides", <FlagOutlined />, [
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq" //A3
                    ? getItemWithGeas()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq" //A3
                    ? getItemWithGeasAdd()
                    : null,
            ]),
        // Staff
        userLevel ===
            "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG" ||
            userLevel === "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea" ||//A5
            userLevel === "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W" || //A8
            userLevel === "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq"//A3
            ? null
            : getItem("Staff", "Staff", <UserOutlined />, [
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C"
                    ? getIteStaffddfsaGe()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C"
                    ? getItemWithStaffdfa()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu"
                    ? getItemAddAllStaffAz()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu"
                    ? getItemAllStaffAz()
                    : null,
            ]),
        // Menu az
        userLevel ===
            "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq" ||//A3
            userLevel ===
            "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C" ||
            userLevel ===
            "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W" || //A8
            userLevel ===
            "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG" ||
            userLevel === "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu" //A2
            ? null
            : getItem("Menu", "menu", <CoffeeOutlined />, [
                userName === "zaurkhan" ||
                    userName === "Asif" ||
                    userLevel ===
                    "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea"
                    ? getItemMenuAdd()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea"
                    ? getItemMenuTable()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea"
                    ? getItemMenuPdf()
                    : null,
            ]),
        // Menu Geo
        userLevel ===
            "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq" || //A3
            userLevel === "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea" ||//A5
            userLevel ===
            "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C" ||
            userLevel ===
            "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG" ||
            userLevel === "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu"//A2
            ? null
            : getItem("Menu-Geo", "menuGeo", <ReadOutlined />, [
                userName === "zaurkhan" ||
                    userName === "Asif" ||
                    userLevel ===
                    "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W"
                    ? getItemMenuAddGeo()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W"
                    ? getItemMenuTableGeo()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W"
                    ? getItemMenuPdfGeo()
                    : null,
            ]),
        // Üzv
        userLevel ===
            "$2y$10$diSBgQm7FLhsklZ9aYHcu.rX2YM0cvXVWygR4XDDU04JiLAuokdbq" ||//A3
            userLevel ===
            "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu" ||//A2
            userLevel ===
            "$2y$10$tMzd38OQ8NlxFrp9lhIdiOm/PA6eE5Oj./lZWJMsmIPzJwi6hAk.W" || //A8
            userLevel ===
            "$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C" ||
            userLevel ===
            "$2y$10$pCknDwpzBgWxi/3eXb4xyOPDo3gnSiPtVptMKt3B0lZSwvAUYr1ea" ||//A5
            userLevel === "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG"
            ? null
            : getItem("Üzv", "uzv", <FileProtectOutlined />, [
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG"
                    ? getItemAddGrils()
                    : null,
                userLevel ===
                    "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                    userLevel ===
                    "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG"
                    ? getItemAddGrilsTable()
                    : null,
            ]),
        // Settings
        getItem("Settings", "Settings", <SettingOutlined />, [
            userLevel ===
                "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy" ||
                userLevel ===
                "$2y$10$LwjXBy0erxrhBrNdY5emY.mWf/flFGniz6B3NIWsNPdRItz9Iz.Pu"
                ? PDFS()
                : null,
            Photo(),
            Settings()
        ]),
    ];
    // Bələdçilər-AZE
    function getItemWithCondition() {
        const label =
            userLevel ===
                "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy"
                ? "Bələdçilər-AZE"
                : "Bələdçilər";
        return {
            label: (
                <a href="/Tables" rel="noopener noreferrer">
                    {label}
                </a>
            ),
            key: label,
            icon: <AppstoreOutlined />,
        };
    }
    function getItemWithConditionAdd() {
        return {
            label: (
                <a href="/ADD" rel="noopener noreferrer">
                    Yeni Bələdçi
                </a>
            ),
            icon: <AppstoreAddOutlined />,
        };
    }
    // Bələdçilər-GE
    function getItemWithGeas() {
        const label =
            userLevel ===
                "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy"
                ? "Bələdçilər-GE"
                : "Guides";
        return {
            label: (
                <a href="/TableGe" rel="noopener noreferrer">
                    {label}
                </a>
            ),
            key: "Bələdçilər-GE",
            icon: <AppstoreOutlined />,
        };
    }
    function getItemWithGeasAdd() {
        const label =
            userLevel ===
                "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy"
                ? "Yeni Bələdçi-GE"
                : "New Guides";
        return {
            label: (
                <a href="/ADDGe" rel="noopener noreferrer">
                    {label}
                </a>
            ),
            key: "ADDge",
            icon: <AppstoreOutlined />,
        };
    }
    function getItemWithCardAll() {
        return {
            label: (
                <a href="/All" rel="noopener noreferrer">
                    Bütün Bələdçi-card
                </a>
            ),
            key: "Allui",
            icon: <AppstoreAddOutlined />,
        };
    }
    // Yeni Staff-GE
    function getIteStaffddfsaGe() {
        const label =
            userLevel ===
                "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy"
                ? "Staff-GE"
                : "Staff";
        return {
            label: (
                <a href="/personal-ge" rel="noopener noreferrer">
                    {label}
                </a>
            ),
            key: "Staff-GE",
            icon: <SolutionOutlined />,
        };
    }
    function getItemWithStaffdfa() {
        const label =
            userLevel ===
                "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy"
                ? "Yeni Staff-GE"
                : "New Staff";
        return {
            label: (
                <a href="/Add-Personal-GE" rel="noopener noreferrer">
                    {label}
                </a>
            ),
            key: "Addge",
            icon: <AppstoreOutlined />,
        };
    }
    //Staff Aze
    function getItemAddAllStaffAz() {
        return {
            label: (
                <a href="/AddPersonal" rel="noopener noreferrer">
                    Yeni STAFF
                </a>
            ),
            key: "NewStaf",
            icon: <UserAddOutlined />,
        };
    }
    function getItemAllStaffAz() {
        return {
            label: (
                <a href="/personal" rel="noopener noreferrer">
                    Staff
                </a>
            ),
            key: "Staf",
            icon: <SolutionOutlined />,
        };
    }
    // Menu
    function getItemMenuAdd() {
        return {
            label: (
                <a href="/menuAdd" rel="noopener noreferrer">
                    Menu Add
                </a>
            ),
            key: "Menu",
            icon: <MenuOutlined />,
        };
    }
    function getItemMenuTable() {
        return {
            label: (
                <a href="/Tablemenu" rel="noopener noreferrer">
                    Menu
                </a>
            ),
            key: "Menu5",
            icon: <ProfileOutlined />,
        };
    }
    function getItemMenuPdf() {
        return {
            label: (
                <a href="/MenuPdf" rel="noopener noreferrer">
                    Menu PDF
                </a>
            ),
            key: "Menu pdf",
            icon: <ReadOutlined />,
        };
    }
    // Menu GEo
    function getItemMenuAddGeo() {
        return {
            label: (
                <a href="/NewMenuGeo" rel="noopener noreferrer">
                    Menu Add
                </a>
            ),
            key: "Menu",
            icon: <MenuOutlined />,
        };
    }
    function getItemMenuTableGeo() {
        return {
            label: (
                <a href="/MenugeoTable" rel="noopener noreferrer">
                    Menu Table
                </a>
            ),
            key: "Menu5",
            icon: <ProfileOutlined />,
        };
    }
    function getItemMenuPdfGeo() {
        return {
            label: (
                <a href="/MenuGeu" rel="noopener noreferrer">
                    Menu PDF
                </a>
            ),
            key: "Menu pdf",
            icon: <ReadOutlined />,
        };
    }
    //Uzvler
    function getItemAddGrils() {
        return {
            label: (
                <a href="/üzv" rel="noopener noreferrer">
                    Yeni Üzv
                </a>
            ),
            key: "Adduzv",
            icon: <UserAddOutlined />,
        };
    }
    function getItemAddGrilsTable() {
        return {
            label: (
                <a href="/üzvTable" rel="noopener noreferrer">
                    Üzvlər
                </a>
            ),
            key: "Uzvlər",
            icon: <WomanOutlined />,
        };
    }
    //PDF
    function PDFS() {
        return {
            label: (
                <a href="/DownloafPdf" rel="noopener noreferrer">
                    PDF Yüklə
                </a>
            ),
            key: "addpdf",
            icon: <FilePdfOutlined />,
        };
    }
    //Photo
    function Photo() {
        return {
            label: (
                <a href="/Webcam" rel="noopener noreferrer">
                    Camera
                </a>
            ),
            key: "SPhoto",
            icon: <VideoCameraOutlined twoToneColor="#eb2f96" />,
        };
    }
    //Settings
    function Settings() {
        return {
            label: (
                <a href="/Settings" rel="noopener noreferrer">
                    Settings
                </a>
            ),
            key: "Settings",
            icon: <SettingOutlined />,
        };
    }
    const onClick = (e) => {
        console.log("click", e);
    };
    const [openKeys, setOpenKeys] = useState(["sub1"]);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const [current, setCurrent] = useState("1");
    const [clickCount, setClickCount] = useState(0);
    const handleImageClick = () => {
        setClickCount(clickCount + 1);

        if (clickCount === 3) { // When clicked 4 times (0-based index)
            // Trigger navigation to "/game"
            window.location.href = "/game";
        }
    };

    useEffect(() => {
        if (clickCount === 4) {
            // Reset click count after 4 clicks
            setClickCount(0);
        }
    }, [clickCount]);

    return (
        <div className="hidden fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                <a href="/main" data-tooltip-target="tooltip-home" type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    <span className="sr-only">Home</span>
                </a>
                <div id="tooltip-home" role="tooltip" className="absolute z-10 inopen inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Home
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <a href="/ADD" data-tooltip-target="tooltip-wallet" type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z" />
                        <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z" />
                    </svg>
                    <span className="sr-only">Wallet</span>
                </a>
                <div id="tooltip-wallet" role="tooltip" className="absolute z-10 inopen inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Wallet
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <div className="flex items-center justify-center">
                    <button data-tooltip-target="tooltip-new" type="button" className="inline-flex items-center justify-center w-10 h-10 font-medium bg-blue-600 rounded-full hover:bg-blue-700 group focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                        <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                        </svg>
                        <span className="sr-only">New item</span>
                    </button>
                </div>
                <div id="tooltip-new" role="tooltip" className="absolute z-10 inopen inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Create new item
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <button data-tooltip-target="tooltip-profile" type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>
                    <span className="sr-only">Profile</span>
                </button>
                <div id="tooltip-profile" role="tooltip" className="absolute z-10 inopen inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Profile
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <a href="/settings" data-tooltip-target="tooltip-settings" type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2" />
                    </svg>
                    <span className="sr-only">Settings</span>
                </a>
                <div id="tooltip-settings" role="tooltip" className="absolute z-10 inopen inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Settings
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            </div>
        </div>
    );
};

export default Tabbar;
