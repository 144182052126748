import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from '@ant-design/plots';
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const StatMasa = () => {
  const [chartData, setChartData] = useState([]);

  // Fetch data from the API
  const fetchChartData = async () => {
    try {
      const response = await axios.get(`${CONN_KEY}app/web/StatUMasa.php`);
      // Verify that the response data is an array
      if (Array.isArray(response.data)) {
        // Set the chart data
        setChartData(response.data);
      } else {
        console.error("Unexpected response structure:", response.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  // Chart configuration
  const config = {
    data: chartData,
    xField: 'Masa', // The column representing the Masa value
    yField: 'count', // The column representing the count of selections for each Masa
    height: 300,
    autoFit: false,
    colorField: 'Masa', // You can set different colors for different Masa values
    columnWidthRatio: 0.6, // Set the column width ratio
    legend: {},
    mark: 'cell',
    style: { inset: 0.5 },
    tooltip: {
      title: 'date',
      field: 'temp_max',
      valueFormatter: '~s',
      pointerEvents: 'none'
    },
  };

  return <Line {...config} />;
};

export default StatMasa;
