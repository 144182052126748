
import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row, Select, Space, Upload } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Link, useNavigate, useParams } from 'react-router-dom';
const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;
const { Option } = Select;

const UpdateG = () => {
    const [data, setData] = useState({});
    const [brands, setBrands] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageSH, setSelectedImageSH] = useState(null);
    const { UserId } = useParams();
    const [permissions, setPermissions] = useState({});
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    
    useEffect(() => {
        // Fetch permissions from session storage
        const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
        console.log('Stored Permissions:', storedPermissions);

        if (storedPermissions && storedPermissions.length > 0) {
            setPermissions(storedPermissions[0]);
        }

        // Check if GuidesAzAdd permission is 0 and navigate if true
        if (storedPermissions && storedPermissions[0].AboneEdit === 0) {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        // Fetch brand data from the server
        const fetchBrands = async () => {
            try {
                const response = await axios.get(CONN_KEY_SENAT + 'AddGrils.php');
                setBrands(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        // Fetch user data based on UserId
        const fetchData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY_SENAT}AddGrils.php?userid=${UserId}`);
                setData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [UserId]);

    const onGenderChange = (value) => {
        form.setFieldsValue({
            Beletcinum: undefined,
        });
    };

    const onFinish = async (values) => {
        try {
            const response = await axios.put(`${CONN_KEY_SENAT}updateGrils.php`, values, {
                params: {
                    userid: UserId,
                },
            });

            if (response.data.status === 0) {
                message.error(response.data.message);
                return;
            }

            message.success(response.data.message);
        } catch (error) {
            console.error(error);
        }
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            form.setFieldsValue(data);
        }
    }, [form, data]);

    const handleSubmitPP = () => {
        if (selectedImage) {
            const formData = new FormData();
            formData.append('file', selectedImage);

            // Make a POST request to updateimageStaffPP.php
            fetch(`${CONN_KEY_SENAT}Upgm34Girls.php?userid=${UserId}`, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response data
                    if (data.success) {
                        message.success(data.message); // Success message
                    } else {
                        message.error(data.message); // Error message
                    }
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    message.error(error);
                });
        }
    };

    const handleSubmit = () => {
        if (selectedImageSH) {
            const formData = new FormData();
            formData.append('file', selectedImageSH);

            // Make a POST request to USHimg.php
            fetch(`${CONN_KEY_SENAT}USHimgGirls.php?userid=${UserId}`, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response data
                    if (data.success) {
                        message.success(data.message); // Success message
                    } else {
                        message.error(data.message); // Error message
                    }
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    message.error(error);
                });
        }
    };

    return (
        <div className="container">
            <h1>Vəsiqə nömrəsi {UserId}</h1>
            <Form
                form={form}
                onFinish={onFinish}
                encType="multipart/form-data"
                className="form-Add container"
                layout="vertical"
            >
                <Card>
                    <div style={{ gap: '10px' }}>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Vn №" name="userid">
                                    <Input style={{ width: '100%' }} size="large" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="İstifadəçi adı" name="username">
                                    <Input style={{ width: '100%' }} size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Soyad" name="surename">
                                    <Input style={{ width: '100%' }} size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Ata adı" name="dadname">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.dadname}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="İstifadəçi Fin" name="Fin">
                                    <Input
                                        onKeyPress={(e) => {
                                            if (e.key === ' ') {
                                                e.preventDefault(); // Prevent typing space character
                                            }
                                            e.target.value = e.target.value.toUpperCase();
                                        }}
                                        onChange={(e) => {
                                            const newValue = e.target.value.toUpperCase().replace(/\s/g, ''); // Convert to uppercase and remove spaces
                                            setInputs((prevInputs) => ({
                                                ...prevInputs,
                                                Fin: newValue.replace(/[^A-Z0-9]/g, ''), // Replace non-alphanumeric characters
                                            }));
                                        }}
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.Fin}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Doğum günü" name="datebhrd">
                                    <InputMask
                                        mask="99.99.9999" // Define the input mask pattern
                                        maskChar="0" // Use '0' as the mask character
                                        value={data.datebhrd} // Provide the input value (optional)
                                    >
                                        {(inputProps) => (
                                            <Input
                                                {...inputProps}
                                                style={{ width: "100%" }}
                                                name="datebhrd"
                                                size="large"
                                                placeholder="DD.MM.YYYY"
                                            />
                                        )}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Ölkə" name="Country">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.Country}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Telefon nömrəsi" name="Phone">
                                    <InputMask
                                        mask="999 999 99 99" // Define the input mask
                                        maskChar=" " // Use space as the mask character
                                        value={""} // Set an empty string or the phone number value
                                    >
                                        {(inputProps) => (
                                            <Input
                                                {...inputProps}
                                                style={{ width: "100%" }}
                                                name="Phone"
                                                onKeyPress={(e) => {
                                                    if (e.key === ' ') {
                                                        e.preventDefault(); // Prevent typing space character
                                                    }
                                                }}
                                                size="large"
                                                placeholder="(000) 000 00 00"
                                            />
                                        )}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item'
                                    label="3x4"
                                    name="filePP"
                                    disabled
                                    getValueFromEvent={(e) => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        const fileList = e && e.fileList;
                                        if (fileList) {
                                            return fileList.slice(-1); // Limit the fileList to the last selected file
                                        }
                                        return [];
                                    }}
                                >
                                    <Upload
                                        name="filePP"
                                        method="PUT"
                                        maxCount={1}
                                        listType="picture"
                                        beforeUpload={(file) => {
                                            setSelectedImage(file); // Store the selected image file
                                            return false; // Prevent upload from happening immediately
                                        }}
                                    >
                                        {selectedImage ? (
                                            <img
                                                src={URL.createObjectURL(selectedImage)}
                                                style={{ width: 50 }}
                                                alt="Şəxsiyyət vəsiqəsi"
                                            />
                                        ) : data.filePP ? (
                                            <img
                                                src={`${CONN_KEY_SENAT}/3x4Girl/${data.filePP}`}
                                                style={{ width: 50 }}
                                                alt="Şəxsiyyət vəsiqəsi"
                                            />
                                        ) : null}
                                    </Upload>
                                    <Button type="primary" onClick={handleSubmitPP}>
                                        Yenilə
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item'
                                    label="Scan Şəxsiyyət vəsiqəsi"
                                    name="fileSH"
                                    disabled
                                    getValueFromEvent={(e) => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        const fileList = e && e.fileList;
                                        if (fileList) {
                                            return fileList.slice(-1); // Limit the fileList to the last selected file
                                        }
                                        return [];
                                    }}
                                >
                                    <Upload
                                        name="fileSH"
                                        method="PUT"
                                        maxCount={1}
                                        listType="picture"
                                        beforeUpload={(file) => {
                                            setSelectedImageSH(file); // Store the selected image file
                                            return false; // Prevent upload from happening immediately
                                        }}
                                    >
                                        {selectedImageSH ? (
                                            <img
                                                src={URL.createObjectURL(selectedImageSH)}
                                                style={{ width: 150 }}
                                                alt="Şəxsiyyət vəsiqəsi"
                                            />
                                        ) : data.fileSH ? (
                                            <img
                                                src={`${CONN_KEY_SENAT}scanGirl/${data.fileSH}`}
                                                style={{ width: 150 }}
                                                alt="Şəxsiyyət vəsiqəsi"
                                            />
                                        ) : null}
                                    </Upload>
                                    <Button type="primary" onClick={handleSubmit}>
                                        Yenilə
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space>
                            <Link to={`/PDF-Uzv/${data.userid}`} target='_blank'>
                                <Button icon={<FilePdfOutlined />}>
                                    PDF
                                </Button>
                            </Link>
                            <Button htmlType="submit"
                                // 
                                type="primary" className="ColorMain">
                                Yenilə
                            </Button>
                        </Space>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default UpdateG;
