import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Card } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import PermissionsModal from './UsersList';

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

const NewUsers = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const response = await axios.post(`${CONN_KEY}user.php`, {
        username: values.username,
        password: values.password,
      });

      console.log(response.data);

      if (response.data.status === 1) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error('An error occurred while creating the user.');
    }
  };

  const onCreate = (values) => {
    onFinish(values);
    setOpen(false);
  };

  return (
    <>
      <h3>İstifadəçi</h3>
      <Button type="primary" onClick={() => setOpen(true)}>
        Yeni İstifadəçi
      </Button>
      <br />
      <Button type="primary" onClick={showModal}>
        İcazələr
      </Button>
      <PermissionsModal visible={isModalVisible} onCancel={handleCancel} />
      <Modal
        visible={open}
        title="Yeni İstifadəçi Yarat"
        okText="Yarat"
        cancelText="Ləğv et"
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onCreate(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="user_form"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Ad" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Şifrə" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewUsers;
