import { Button, Card, Form, Input, message, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const App = () => {
    const [permissions, setPermissions] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch permissions from session storage
        const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
        console.log('Stored Permissions:', storedPermissions);

        if (storedPermissions && storedPermissions.length > 0) {
            setPermissions(storedPermissions[0]);
        }

        // Check if MenuazAdd permission is 0 and navigate if true
        if (storedPermissions && storedPermissions[0].MenuazAdd === 0) {
            navigate("/");
        }
    }, []);
    const onFinish = async (values) => {
        try {
            const response = await axios.post(`${CONN_KEY}menu.php`, {
                Catagory: values.Catagory,
                product: values.product,
                price: values.price,
            });

            console.log(response.data);

            if (response.data.status === 1) {
                // Save Catagory as a cookie with partition key
                message.success(response.data.message);
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            // handle error
        }
    };
    const options = [
        {
            value: 'WHISKEY',
        },
        {
            value: 'VODKA',
        },
        {
            value: 'TEQUILA',
        },
        {
            value: 'GIN',
        },
        {
            value: 'COGNAC',
        },
        {
            value: 'CHAMPAGNE',
        },
        {
            value: 'WİNE',
        },
        {
            value: 'VERMUT',
        },
        {
            value: 'APERETIV',
        },
        {
            value: 'LIQUOR',
        },
        {
            value: 'ROM',
        },
        {
            value: 'BEER',
        },
        {
            value: 'SNACKS',
        },
        {
            value: 'TOBACCO',
        },
        {
            value: 'COCKTAILS',
        },
        {
            value: 'SOFT DRINKS',
        },
        {
            value: 'VAPE',
        },
    ];
    return (
        <Card
            title="Yeni menu"
            style={{ maxWidth: 500, }}
        >
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="Catagory"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Catagory!',
                        },
                    ]}
                >
                    <Select
                        showArrow
                        // initialValues={['WHISKEY']}
                        style={{
                            width: '100%',
                        }}
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    name="product"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your product!',
                        },
                    ]}
                >
                    <Input
                        placeholder="product"
                    />
                </Form.Item>
                <Form.Item
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your price!',
                        },
                    ]}
                >
                    <Input
                        placeholder="price"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default App;
