import { Button, Skeleton, Space, message } from "antd";
import axios from "axios";
import html2pdf from "html2pdf.js";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../App.css";

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const CONN_KEY_SENAT_main = process.env.REACT_APP_NOT_SECRET_CODE_R;

axios.interceptors.request.use((config) => {
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Headers"] = "*";
  config.headers["Access-Control-Allow-Methods"] = "*";
  return config;
});

function Fakturas() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const invoiceSectionRef = useRef(null);
  const { id } = useParams();
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if Menuaz permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].Menuaz === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fakturaUrl = `${CONN_KEY}menu.php`;
        const fakturaResponse = await axios.get(fakturaUrl);
        setData(fakturaResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleDownload = async () => {
    if (invoiceSectionRef.current) {
      const originalContents = document.body.innerHTML;
      const images = invoiceSectionRef.current.getElementsByTagName("img");

      const loadImagePromises = Array.from(images).map(async (image) => {
        try {
          const imageUrl = image.src; // Use the direct image URL
          const response = await axios.get(imageUrl, { responseType: "blob" });
          const blob = response.data;
          const reader = new FileReader();

          const base64Promise = new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
          });

          reader.readAsDataURL(blob);
          const base64DataUrl = await base64Promise;
          image.src = base64DataUrl;
        } catch (error) {
          console.error("Error downloading image:", error);
        }
      });

      try {
        await Promise.all(loadImagePromises);
      } catch (error) {
        console.error("Error loading images:", error);
        document.body.innerHTML = originalContents;
        return;
      }

      const opt = {
        margin: 0,
        filename: `menu.pdf`,
        image: { type: "jpeg", quality: 0.5 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      html2pdf()
        .set(opt)
        .from(invoiceSectionRef.current)
        .save()
        .then(() => {
          // Restore the original contents of the page
          document.body.innerHTML = originalContents;
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          document.body.innerHTML = originalContents;
          // Display error message
          message.error("Error generating PDF. Please try again.");
        });
    }
  };

  console.log("isLoading:", isLoading);
  const items = Object.keys(data)
    .filter((key) => data[key].Catagory === "CATEGORY_NAME") // Replace CATEGORY_NAME with the actual category name
    .map((key) => data[key])
    .sort((a, b) => a.product.localeCompare(b.product)); // Sort items by product name

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Space>
            {/* <Button onClick={handlePrint}>Print</Button> */}
            <Button onClick={handleDownload}>Pdf'i Yarat</Button>
          </Space>
          <div className="container">
            <div id="invoice-section" ref={invoiceSectionRef}>
              <div className="jjhgdf-menu-1 page-menu">
                <div className="djhvms">
                  <h1 className="dhjsfbs2" style={{ top: "264px" }}>
                    WHISKEY
                  </h1>
                  <div className="ssfsdb">
                    {/* Inside the "WHISKEY" category section: */}
                    <div className="ssfsdb">
                      {/* Inside the "WHISKEY" category section: */}
                      {/* Inside the "WHISKEY" category section: */}
                      <div className="ssfsdb">
                        {Object.keys(data)
                          .filter((key) => data[key].Catagory === "WHISKEY")
                          .map((key) => data[key])
                          .sort((a, b) => {
                            const nameA = a.product.toLowerCase();
                            const nameB = b.product.toLowerCase();

                            if (nameA < nameB) return -1;
                            if (nameA > nameB) return 1;

                            // If names are the same, compare prices
                            const priceA = parseFloat(a.price);
                            const priceB = parseFloat(b.price);

                            return priceB - priceA;
                          })
                          .map((item) => (
                            <div className="cardssds" key={item.product}>
                              <p>{item.product}</p>
                              <p>{item.price}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jjhgdf-menu-2 page-menu">
                <div className="ssfsdbVOD" style={{ backdropFilter: 'blur(25px)' }}>
                  <h1
                    className="dhjsfbs2"
                    style={{ top: "1322px", left: "285px" }}
                  >
                    VODKA
                  </h1>
                  {Object.keys(data)
                    .filter((key) => data[key].Catagory === "VODKA")
                    .map((key) => data[key])
                    .sort((a, b) => {
                      const nameA = a.product.toLowerCase();
                      const nameB = b.product.toLowerCase();

                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;

                      // If names are the same, compare prices
                      const priceA = parseFloat(a.price);
                      const priceB = parseFloat(b.price);

                      return priceB - priceA;
                    })
                    .map((item) => (
                      <div className="cardssdsVODKA" key={item.product}>
                        <p>{item.product}</p>
                        <p>{item.price}</p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="jjhgdf-menu-3 page-menu">
                <div className="dhvsj">
                  <div className="ssfsdbWC">
                    <h1
                      className="dhjsfbs2"
                      style={{ top: "1322px", left: "285px" }}
                    >
                      COGNAC
                    </h1>
                    {Object.keys(data)
                      .filter((key) => data[key].Catagory === "COGNAC")
                      .map((key) => data[key])
                      .sort((a, b) => {
                        const nameA = a.product.toLowerCase();
                        const nameB = b.product.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        // If names are the same, compare prices
                        const priceA = parseFloat(a.price);
                        const priceB = parseFloat(b.price);

                        return priceB - priceA;
                      })
                      .map((item) => (
                        <div className="cardssdsWİNE" key={item.product}>
                          <p>{item.product}</p>
                          <p>{item.price}</p>
                        </div>
                      ))}
                  </div>
                  <div className="ssfsdbWCH">
                    <h1
                      className="dhjsfbs2"
                      style={{ top: "1322px", left: "285px" }}
                    >
                      CHAMPAGNE
                    </h1>
                    {Object.keys(data)
                      .filter((key) => data[key].Catagory === "CHAMPAGNE")
                      .map((key) => data[key])
                      .sort((a, b) => {
                        const nameA = a.product.toLowerCase();
                        const nameB = b.product.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        // If names are the same, compare prices
                        const priceA = parseFloat(a.price);
                        const priceB = parseFloat(b.price);

                        return priceB - priceA;
                      })
                      .map((item) => (
                        <div className="cardssdsCHAMPAGNE" key={item.product}>
                          <p>{item.product}</p>
                          <p>{item.price}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="jjhgdf-menu-4 page-menu">
                <div className="dhvsjdfd">
                  <div className="ssfsdbTEQUILA">
                    <h1
                      className="dhjsfbs2"
                      style={{ top: "1322px", left: "285px" }}
                    >
                      TEQUILA
                    </h1>
                    {Object.keys(data)
                      .filter((key) => data[key].Catagory === "TEQUILA")
                      .map((key) => data[key])
                      .sort((a, b) => {
                        const nameA = a.product.toLowerCase();
                        const nameB = b.product.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        // If names are the same, compare prices
                        const priceA = parseFloat(a.price);
                        const priceB = parseFloat(b.price);

                        return priceB - priceA;
                      })
                      .map((item) => (
                        <div className="cardssdsCHAMPAGNE" key={item.product}>
                          <p>{item.product}</p>
                          <p>{item.price}</p>
                        </div>
                      ))}
                  </div>
                  <div className="ssfsdbGIN">
                    <h1
                      className="dhjsfbs2"
                      style={{ top: "1322px", left: "285px" }}
                    >
                      GIN
                    </h1>
                    {Object.keys(data)
                      .filter((key) => data[key].Catagory === "GIN")
                      .map((key) => data[key])
                      .sort((a, b) => {
                        const nameA = a.product.toLowerCase();
                        const nameB = b.product.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        // If names are the same, compare prices
                        const priceA = parseFloat(a.price);
                        const priceB = parseFloat(b.price);

                        return priceB - priceA;
                      })
                      .map((item) => (
                        <div className="cardssdsCHAMPAGNE" key={item.product}>
                          <p>{item.product}</p>
                          <p>{item.price}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="jjhgdf-menu-5 page-menu">
                <div className="dhvsjdfd">
                  <div className="sfsdfaf">
                    <div className="SADFGV">
                      <h1
                        className="dhjsfbs2"
                        style={{ top: "1322px", left: "285px" }}
                      >
                        WİNE
                      </h1>
                      {Object.keys(data)
                        .filter((key) => data[key].Catagory === "WİNE")
                        .map((key) => data[key])
                        .sort((a, b) => {
                          const nameA = a.product.toLowerCase();
                          const nameB = b.product.toLowerCase();

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          // If names are the same, compare prices
                          const priceA = parseFloat(a.price);
                          const priceB = parseFloat(b.price);

                          return priceB - priceA;
                        })
                        .map((item) => (
                          <div className="cardssdsVODKA" key={item.product}>
                            <p>{item.product}</p>
                            <p>{item.price}</p>
                          </div>
                        ))}
                    </div>
                    <div className="APERETIVasf">
                      <h1
                        className="dhjsfbs2"
                        style={{ top: "1322px", left: "285px" }}
                      >
                        VERMUT
                      </h1>
                      {Object.keys(data)
                        .filter((key) => data[key].Catagory === "VERMUT")
                        .map((key) => data[key])
                        .sort((a, b) => {
                          const nameA = a.product.toLowerCase();
                          const nameB = b.product.toLowerCase();

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          // If names are the same, compare prices
                          const priceA = parseFloat(a.price);
                          const priceB = parseFloat(b.price);

                          return priceB - priceA;
                        })
                        .map((item) => (
                          <div className="cardssdsVODKA" key={item.product}>
                            <p>{item.product}</p>
                            <p>{item.price}</p>
                          </div>
                        ))}
                    </div>
                    <div className="ssfsagasdf">
                      <h1
                        className="dhjsfbs2"
                        style={{ top: "1322px", left: "285px" }}
                      >
                        APERETIV
                      </h1>
                      {Object.keys(data)
                        .filter((key) => data[key].Catagory === "APERETIV")
                        .map((key) => data[key])
                        .sort((a, b) => {
                          const nameA = a.product.toLowerCase();
                          const nameB = b.product.toLowerCase();

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          // If names are the same, compare prices
                          const priceA = parseFloat(a.price);
                          const priceB = parseFloat(b.price);

                          return priceB - priceA;
                        })
                        .map((item) => (
                          <div className="cardssdsVODKA" key={item.product}>
                            <p>{item.product}</p>
                            <p>{item.price}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="jjhgdf-menu-6 page-menu">
                <div className="dhvsjdfg" style={{ marginTop: 75 }}>
                  <div className="SADFGV">
                    <h1
                      className="dhjsfbs2"
                      style={{ top: "1322px", left: "285px" }}
                    >
                      LIQUOR
                    </h1>
                    {Object.keys(data)
                      .filter((key) => data[key].Catagory === "LIQUOR")
                      .map((key) => data[key])
                      .sort((a, b) => {
                        const nameA = a.product.toLowerCase();
                        const nameB = b.product.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        // If names are the same, compare prices
                        const priceA = parseFloat(a.price);
                        const priceB = parseFloat(b.price);

                        return priceB - priceA;
                      })
                      .map((item) => (
                        <div className="cardssdsVODKA" key={item.product}>
                          <p>{item.product}</p>
                          <p>{item.price}</p>
                        </div>
                      ))}
                  </div>
                  <div className="APERETIVasf">
                    <h1
                      className="dhjsfbs2"
                      style={{ top: "1322px", left: "285px" }}
                    >
                      ROM
                    </h1>
                    {Object.keys(data)
                      .filter((key) => data[key].Catagory === "ROM")
                      .map((key) => data[key])
                      .sort((a, b) => {
                        const nameA = a.product.toLowerCase();
                        const nameB = b.product.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        // If names are the same, compare prices
                        const priceA = parseFloat(a.price);
                        const priceB = parseFloat(b.price);

                        return priceB - priceA;
                      })
                      .map((item) => (
                        <div className="cardssdsVODKA" key={item.product}>
                          <p>{item.product}</p>
                          <p>{item.price}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="jjhgdf-menu-7 page-menu">
                <div className="dhvsjd" style={{ marginTop: 180 }}>
                  <div className="jkhgdfv">
                    <div className="SADFGV">
                      <h1
                        className="dhjsfbs2"
                        style={{ top: "1322px", left: "285px" }}
                      >
                        BEER
                      </h1>
                      {Object.keys(data)
                        .filter((key) => data[key].Catagory === "BEER")
                        .map((key) => data[key])
                        .sort((a, b) => {
                          const nameA = a.product.toLowerCase();
                          const nameB = b.product.toLowerCase();

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          // If names are the same, compare prices
                          const priceA = parseFloat(a.price);
                          const priceB = parseFloat(b.price);

                          return priceB - priceA;
                        })
                        .map((item) => (
                          <div className="cardssdsVODKA" key={item.product}>
                            <p>{item.product}</p>
                            <p>{item.price}</p>
                          </div>
                        ))}
                    </div>
                    <div className="APERETIVasft" style={{ marginTop: 50 }}>
                      <h1
                        className="dhjsfbs2"
                        style={{ top: "1322px", left: "285px" }}
                      >
                        SNACKS
                      </h1>
                      {Object.keys(data)
                        .filter((key) => data[key].Catagory === "SNACKS")
                        .map((key) => data[key])
                        .sort((a, b) => {
                          const nameA = a.product.toLowerCase();
                          const nameB = b.product.toLowerCase();

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          // If names are the same, compare prices
                          const priceA = parseFloat(a.price);
                          const priceB = parseFloat(b.price);

                          return priceB - priceA;
                        })
                        .map((item) => (
                          <div className="cardssdsVODKA" key={item.product}>
                            <p>{item.product}</p>
                            <p>{item.price}</p>
                          </div>
                        ))}
                    </div>
                    <div className="ssfsagasdf">
                      <h1
                        className="dhjsfbs2"
                        style={{ top: "1322px", left: "285px" }}
                      >
                        TOBACCO
                      </h1>
                      {Object.keys(data)
                        .filter((key) => data[key].Catagory === "TOBACCO")
                        .map((key) => data[key])
                        .sort((a, b) => {
                          const nameA = a.product.toLowerCase();
                          const nameB = b.product.toLowerCase();

                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;

                          // If names are the same, compare prices
                          const priceA = parseFloat(a.price);
                          const priceB = parseFloat(b.price);

                          return priceB - priceA;
                        })
                        .map((item) => (
                          <div className="cardssdsVODKA" key={item.product}>
                            <p>{item.product}</p>
                            <p>{item.price}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="jjhgdf-menu-8 page-menu">
                <div className="ssfsdbVODs" style={{ marginTop: 60 }}>
                  <h1
                    className="dhjsfbs2"
                    style={{ top: "1322px", left: "285px" }}
                  >
                    COCKTAILS
                  </h1>
                  {Object.keys(data)
                    .filter((key) => data[key].Catagory === "COCKTAILS")
                    .map((key) => data[key])
                    .sort((a, b) => {
                      const nameA = a.product.toLowerCase();
                      const nameB = b.product.toLowerCase();

                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;

                      // If names are the same, compare prices
                      const priceA = parseFloat(a.price);
                      const priceB = parseFloat(b.price);

                      return priceB - priceA;
                    })
                    .map((item) => (
                      <div className="cardssdsVODKA" key={item.product}>
                        <p>{item.product}</p>
                        <p>{item.price}</p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="jjhgdf-menu-9 page-menu">
                <div className="ssfsdbVOD" style={{ top: "-579px" }}>
                  <h1
                    className="dhjsfbs2"
                    style={{ top: "1322px", left: "285px" }}
                  >
                    SOFT DRINKS
                  </h1>
                  {Object.keys(data)
                    .filter((key) => data[key].Catagory === "SOFT DRINKS")
                    .map((key) => data[key])
                    .sort((a, b) => {
                      const nameA = a.product.toLowerCase();
                      const nameB = b.product.toLowerCase();

                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;

                      // If names are the same, compare prices
                      const priceA = parseFloat(a.price);
                      const priceB = parseFloat(b.price);

                      return priceB - priceA;
                    })
                    .map((item) => (
                      <div className="cardssdsVODKA" key={item.product}>
                        <p>{item.product}</p>
                        <p>{item.price}</p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="jjhgdf-menu-10 page-menu">
                <div className="ssfsdbVOD">
                  <h1
                    className="dhjsfbs2"
                    style={{ top: "1322px", left: "285px" }}
                  >
                    VAPE
                  </h1>
                  {Object.keys(data)
                    .filter((key) => data[key].Catagory === "VAPE")
                    .map((key) => data[key])
                    .sort((a, b) => {
                      const nameA = a.product.toLowerCase();
                      const nameB = b.product.toLowerCase();

                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;

                      // If names are the same, compare prices
                      const priceA = parseFloat(a.price);
                      const priceB = parseFloat(b.price);

                      return priceB - priceA;
                    })
                    .map((item) => (

                      <div className="cardssdsVODKA" key={item.product}>
                        <p>{item.product}</p>
                        <p>{item.price}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Fakturas;
