import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "./components/notfound";
import "./App.css";
import SellingItem from "./pages/Guide.az/Add";
import Mainpage from "./pages/Mainpage";
import Faktura from "./pages/Faktura";
import CardGe from "./pages/CardGE";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Pdf from "./pages/Guide.az/PDF";
import PdfGE from "./pages/PDFGE";
import Cards from "./pages/Guide.az/Cards";
import UpdatePda from "./pages/Update";
import PDFDownload from "./pages/PdfDownload";
import Table from "./pages/Tables";
import TabllesGe from "./pages/TablesGe";
import AddPress from "./pages/Addpersonal";
import Cardw from "./pages/CardWorck";
import TableStaff from "./pages/Stafftbale";
import Staffpdfpage from "./pages/StaffPdf";
import AppGE from "./pages/AddGE";
import UpdatePdaSTAFF from "./pages/UpdateStaff";
import Face from "./pages/Facetracker";
import UpdateGE from "./pages/UpdateGE";
import Menupage from "./pages/MenuAz/Menu";
import Addmmenupage from "./pages/MenuAz/Addmenu";
import Menupageedit from "./pages/MenuAz/Edidtmenu";
import UpdateM from "./pages/MenuAz/Updatemenu";
import axios from "axios";
import AppGril from "./pages/Girls/AddGrils";
import TableGirls from "./pages/Girls/TablesGirls";
import UpdatePdaG from "./pages/Girls/UpdateGirls";
import OPDFUzv from "./pages/Girls/PDFy";
import AppPresssGe from "./pages/AddpersonalGE";
import TableStaffge from "./pages/StafftbaleGe";
import UpdatePdaSTAFFge from "./pages/UpdateStaffGe";
import CardwGE from "./pages/CardWorckGe";
import StaffpdfpageGe from "./pages/StaffPdfGe";
import Webcamc from "./pages/WebcamCapture";
import GoogleAnalytics from "./components/Google";
import Tictak from "./pages/Tickşjsdd";
import Imageupload from "./pages/Uploaderimage";
import MaasHesaplama from "./pages/Maaş";
import MenuGeu from "./pages/MenuGeo/MenuGeo";
import AddmmenupageGE from "./pages/MenuGeo/Addmenugeu";
import MenupageeditGeo from "./pages/MenuGeo/EdidtmenuGeo";
import UpdateMGeu from "./pages/MenuGeo/UpdatemenuGeo";
import CardsGeoStaff from "./pages/CardStaffGeoAll";
import CardsGE from "./pages/Cards";
import Mapmain from "./pages/Map";
import Printpage from "./components/Map/PrintPage";
import PrintpageEllips from "./components/Map/PrintPageEllips";
import Mapmain3D from "./pages/Map3D";
import Ordergirls from "./pages/Girls/Faktura";
import { notification } from "antd";

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

function App() {
  const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [notificationShown, setNotificationShown] = useState(false);

  const handleUserActivity = () => {
    setLastActivityTime(Date.now());
  };

  const handleLogout = (username) => {
    axios
      .post(CONN_KEY + "Logout.php", { logout: true, username: username })
      .then((response) => {
        // console.log(response.data);
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  const showNotification = (remainingTime) => {
    if (!notificationShown) {
      const key = "logoutNotification";

      const notificationContent = (
        <div>
          <p>
            Siz sistemdən çıxacaqsınız {Math.floor(remainingTime / 1000)} s.
          </p>
          <p>Çıxışdan qaçmaq üçün aktiv qalın.</p>
        </div>
      );

      notification.open({
        key,
        message: "Session Timeout",
        duration: 0, // Setting duration to 0 keeps the notification open until manually closed
        description: notificationContent,
        onClose: () => {
          console.log("Notification closed.");
        },
      });

      setNotificationShown(true);
    }
  };

  const checkSessionTimeout = () => {
    const timeElapsed = Date.now() - lastActivityTime;
    const remainingTime = TIMEOUT_DURATION - timeElapsed;

    if (timeElapsed >= TIMEOUT_DURATION) {
      const username = localStorage.getItem("username");
      handleLogout(username);
    } else if (remainingTime <= 60000 && remainingTime > 0) {
      showNotification(remainingTime);
    }
  };

  useEffect(() => {
    const username = localStorage.getItem("username");

    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);

    const timerId = setInterval(checkSessionTimeout, 1000);

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      clearInterval(timerId);
    };
  }, [lastActivityTime]);

  return (
    <Router>
      <GoogleAnalytics />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Main" element={<Mainpage />} />
        <Route path="/All-Ge" element={<CardsGE />} />
        <Route path="/KhanAuto" element={<MaasHesaplama />} />
        <Route path="/send" element={<Imageupload />} />
        <Route path="/game" element={<Tictak />} />
        <Route path="/FaceTracker" element={<Face />} />
        <Route path="/üzv" element={<AppGril />} />
        <Route path="/üzvtable" element={<TableGirls />} />
        <Route path="/All" element={<Cards />} />
        <Route path="/All-Geo-Staff" element={<CardsGeoStaff />} />
        <Route path="/CardPersonal/:id" element={<Cardw />} />
        <Route path="/CardPersonal-Ge/:id" element={<CardwGE />} />
        <Route path="/personal" element={<TableStaff />} />
        <Route path="/personal-ge" element={<TableStaffge />} />
        <Route path="/AddPersonal" element={<AddPress />} />
        <Route path="/Add-Personal-GE" element={<AppPresssGe />} />
        <Route path="/Tables" element={<Table />} />
        <Route path="/TableGe" element={<TabllesGe />} />
        <Route path="/add" element={<SellingItem />} />
        <Route path="/ADDGe" element={<AppGE />} />
        <Route path="/Cart/:UserId" element={<Faktura />} />
        <Route path="/PDF/:UserId" element={<Pdf />} />
        <Route path="/CartUzv/:UserId" element={<Ordergirls />} />
        <Route path="/PDFUzv/:UserId" element={<OPDFUzv />} />
        <Route path="/CartGE/:UserId" element={<CardGe />} />
        <Route path="/PDFGE/:UserId" element={<PdfGE />} />
        <Route path="/PDFStaff/:id" element={<Staffpdfpage />} />
        <Route path="/PDFStaff-ge/:id" element={<StaffpdfpageGe />} />
        <Route path="/Update/:UserId" element={<UpdatePda />} />
        <Route path="/Update-Üzv/:UserId" element={<UpdatePdaG />} />
        <Route path="/UpdateGE/:UserId" element={<UpdateGE />} />
        <Route path="/UpdateStaff/:id" element={<UpdatePdaSTAFF />} />
        <Route path="/UpdateStaff-ge/:id" element={<UpdatePdaSTAFFge />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/DownloafPdf" element={<PDFDownload />} />
        <Route path="/MenuPdf" element={<Menupage />} />
        <Route path="/MenuADD" element={<Addmmenupage />} />
        <Route path="/Tablemenu" element={<Menupageedit />} />
        <Route path="/Updatemenu/:menu_Id" element={<UpdateM />} />
        <Route path="/Webcam" element={<Webcamc />} />
        <Route path="/mapmain" element={<Mapmain />} />
        <Route path="/3d" element={<Mapmain3D />} />
        <Route path="/printpage" element={<Printpage />} />
        <Route path="/printpageEllips" element={<PrintpageEllips />} />
        <Route path="/UpdatemenuGeo/:menu_Id" element={<UpdateMGeu />} />
        <Route path="/MenuGeu" element={<MenuGeu />} />
        <Route path="/NewMenuGeo" element={<AddmmenupageGE />} />
        <Route path="/MenugeoTable" element={<MenupageeditGeo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
