import { IdcardOutlined } from "@ant-design/icons";
import { Button, Skeleton, Space, message } from "antd";
import axios from "axios";
import html2pdf from "html2pdf.js";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Block from "../../images/block.png";
import "../../App.css";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
axios.interceptors.request.use((config) => {
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Headers"] = "*";
  config.headers["Access-Control-Allow-Methods"] = "*";
  return config;
});
function Fakturas() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { UserId } = useParams();
  const invoiceSectionRef = useRef(null);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({});

  useEffect(() => {

    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].GuidesAz === 0) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fakturaUrl = `${CONN_KEY}Add.php?userid=${UserId}`;
        // console.log('fakturaUrl:', fakturaUrl);
        const fakturaResponse = await axios.get(fakturaUrl);
        // console.log('fakturaResponse:', fakturaResponse.data);

        const beletcinum = fakturaResponse.data.Beletcinum; // Get the Beletcinum value
        const userDataUrl = `${CONN_KEY}Add.php?userid=${beletcinum}`; // Fetch user data based on Beletcinum
        const userDataResponse = await axios.get(userDataUrl);
        // console.log('userDataResponse:', userDataResponse.data);

        const beletcinumUsername = userDataResponse.data.username;
        const beletcinumsurename = userDataResponse.data.surename;
        setData({
          ...fakturaResponse.data,
          beletcinum: beletcinum,
          beletcinumUsername: beletcinumUsername,
          beletcinumsurename: beletcinumsurename,
        });

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [UserId]);

  const handlePrint = () => {
    if (invoiceSectionRef.current) {
      const printContents = invoiceSectionRef.current.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.onload = () => {
        window.print();
        document.body.innerHTML = originalContents;
      };
      message.info("Refreshing the page..."); // Display a message

      // Reload the component after a short delay (adjust the delay as needed)
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000); // Refresh after 1 second (1000 milliseconds)
    }
  };

  const handleDownload = async () => {
    if (invoiceSectionRef.current) {
      const originalContents = document.body.innerHTML;
  
      // Create a new container for the content to be converted to PDF
      const printContainer = document.createElement("div");
      printContainer.innerHTML = invoiceSectionRef.current.innerHTML;
      document.body.appendChild(printContainer);
  
      const images = printContainer.getElementsByTagName("img");
  
      // Convert images to Base64 to avoid CORS issues
      const loadImagePromises = Array.from(images).map(async (image) => {
        try {
          const response = await axios.get(image.src, { responseType: "blob" });
          const reader = new FileReader();
  
          reader.readAsDataURL(response.data);
  
          const base64DataUrl = await new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
          });
  
          image.src = base64DataUrl;
        } catch (error) {
          console.error("Error downloading image:", error);
        }
      });
  
      try {
        await Promise.all(loadImagePromises);
      } catch (error) {
        console.error("Error loading images:", error);
        document.body.innerHTML = originalContents;
        return;
      }
  
      const opt = {
        margin: 0,
        filename: `${data.userid}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: [299.5, 211], orientation: "portrait" },
      };
  
      try {
        // Generate the PDF as a Blob
        const pdfBlob = await html2pdf().set(opt).from(printContainer).outputPdf().then((pdf) => pdf.toBlob());
  
        // Create FormData and append the Blob
        const formData = new FormData();
        formData.append("pdf", pdfBlob, `${data.userid}.pdf`);
  
        // Send the PDF to the server
        await axios.post(`https://senategroup.az/OutoPdf.php`, formData);
  
        message.success("PDF uploaded successfully!");
  
        // Clean up and restore the original page content
        document.body.removeChild(printContainer);
        document.body.innerHTML = originalContents;
  
        // Reload the page
        message.info("Refreshing the page...");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.error("Error generating or uploading PDF:", error);
        document.body.innerHTML = originalContents;
        message.error("Error generating PDF. Please try again.");
      }
    }
  };
  
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Space>
            <Button onClick={handlePrint}>Print</Button>
            <Button className="ColorMain" onClick={handleDownload}>
              Yüklə
            </Button>
            <Link to={`/Cart/${data.userid}`} target="_blank">
              <Button icon={<IdcardOutlined />}>Cart</Button>
            </Link>
          </Space>
          <div className="container">
            <div id="invoice-section" ref={invoiceSectionRef}>
              {data.Status === "Block" && (
                <img className="Block" style={{ opacity: 0.8 }} src={Block} alt="block" />

              )}
              <div className="jjhgdf page">
                <div className="hjhfm">
                  <div className="ttextsd">
                    <h3 className="hw3e namessd">
                      {data.username} {data.surename} <br />
                      {data.dadname}
                    </h3>
                    <h3 className="hw3e ssda">{data.vezife}</h3>
                    {data.vezife !== "Tour Guide" &&
                      data.Beletcinum !== undefined &&
                      data.Beletcinum !== "undefined" && (
                        <>
                          <h3 className="hw3e ssda">
                            Vəsiqə №: {data.Beletcinum}
                          </h3>
                          <h3 className="hw3e ssda">
                            {data.beletcinumUsername} {data.beletcinumsurename}
                          </h3>
                        </>
                      )}
                    <h3 className="hw3e">Fin: {data.Fin}</h3>
                    <h3 className="hw3e">Doğum tarixi: {data.datebhrd}</h3>
                    <h3 className="hw3e">Vətəndaşlığ: {data.Country}</h3>
                    <h3 className="hw3e">Əlaqə: {data.Phone}</h3>
                    <h3 className="hw3e">Vəsiqə №: {data.userid}.pdf</h3>
                    <h3 className="hw3e bime-tarixi">Bimə tarixi: <span style={{ color: 'red', }}>{data.endtime}</span></h3>
                    {data.BlockSeb !== null && data.BlockSebdate !== null && (
                      <>
                        <h3 className="hw3e">Block Səbəbi: {data.BlockSeb}</h3>
                        <h3 className="hw3e">Block Tarixi: {data.BlockSebdate}</h3>
                      </>
                    )}
                  </div>
                  <div className="jshdf">
                    <img
                      className="imgPPDF light-effect"
                      src={`${CONN_KEY}3x4/${data.filePP}`}
                      alt="PP"
                    />
                  </div>
                </div>
                <div className="fdjdx">
                  <img
                    className="imgLKSH"
                    src={`${CONN_KEY}scan/${data.fileSH}`}
                    alt="SH"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Fakturas;
