import { Skeleton, List, Pagination, Button, Input, Space, Select } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

function AllCardsGE() {
    const [isLoading, setIsLoading] = useState(true);
    const { UserId } = useParams();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);

    const pageSize = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fakturaUrl = `${CONN_KEY}AddGE.php`;
                const fakturaResponse = await axios.get(fakturaUrl);
                setData(fakturaResponse.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [UserId]);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const handlePrint = () => {
        const invoiceSection = document.getElementById('invoice-section');
        if (invoiceSection) {
            const printContents = invoiceSection.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.onload = () => {
                window.print();
                document.body.innerHTML = originalContents;
            };
        }
    };

    const handleSearchc = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearch = (selectedValues) => {
        setSearchQuery(selectedValues);

        if (selectedValues.length === 0) {
            setFilteredItems([]); // Reset filtered items when nothing is selected
            return;
        }

        const filteredData = data.filter((item) =>
            selectedValues.some((val) => item.userid.includes(val))
        );

        setCurrentPage(1);
        setFilteredItems(filteredData);
    };

    const filteredData = searchQuery ? data.filter((item) => item.userid.includes(searchQuery)) : data;

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredItems.length > 0 ? filteredItems.slice(startIndex, endIndex) : filteredData.slice(startIndex, endIndex);

    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space className="user-ids">
                        <Button className="ColorMain" onClick={handlePrint}>
                            Print
                        </Button>
                        <Input
                            placeholder="Search userid"
                            value={searchQuery}
                            onChange={handleSearchc}
                            suffix={<SearchOutlined />}
                        />
                        <Select
                            mode="multiple"
                            placeholder="Search userid"
                            value={searchQuery}
                            onChange={handleSearch}
                            style={{ width: 200 }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {data.map((item) => (
                                <Option key={item.userid} value={item.userid}>
                                    {item.userid}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                    <div className="container">
                        <div id="invoice-section">
                            <div className="pages">
                                <List>
                                    <div className="products">
                                        {paginatedData.map((item) => (
                                            <div className="CarVisitALL" key={item.userid} id="invoice-section">
                                                <div className="ihd">
                                                    <div className="useridid">
                                                        <h3 className="isd" style={{ color: 'red' }}>
                                                            V№{item.userid}
                                                        </h3>
                                                    </div>
                                                    <div className="ojdkde">
                                                        <div className="invoicx">
                                                            <img
                                                                className="imgPP light-effect"
                                                                src={`${CONN_KEY}3x4/${item.filePP}`}
                                                                alt={item.filePP}
                                                            />
                                                        </div>
                                                        <div className="jiudgf">
                                                            B {item.vezife === 'Driver' && <span className="spandirver">2</span>}
                                                        </div>
                                                    </div>
                                                    <div className="qr-nameAll">
                                                        <img
                                                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${CONN_KEY}Beledciler/${item.userid}.pdf`}
                                                            alt={`QR code for ${item.userid}`}
                                                            className="qrcartd"
                                                        />
                                                        <div className="hsaddfa">
                                                            <div className="Namecart">
                                                                <h2 className="fontsad" style={{ fontSize: 22 }}>
                                                                    {item.username}
                                                                </h2>
                                                            </div>
                                                            <div className="Namecartd">
                                                                <h2 className="fontsad" style={{ fontSize: 'clamp(14px, 15px, 100%)' }}>
                                                                    {item.surename}
                                                                </h2>
                                                            </div>
                                                            <div className="Namecart">
                                                                <h2 className="fondfg">{item.vezife}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </List>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={filteredData.length}
                        onChange={handleChangePage}
                    />
                </>
            )}
        </>
    );
}

export default AllCardsGE;
