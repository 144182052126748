import { MoreOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Input, Layout, Menu, Modal, Space, Table, Tag, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link, useNavigate } from "react-router-dom";
import Footers from "../components/Footer";
import Headers from "../components/Header";
import MapM1ellips from "../components/Map/EllipsMap/MapEllipsM1";
import MapM2ellips from "../components/Map/EllipsMap/MapEllipsM2";
import MapM3ellips from "../components/Map/EllipsMap/MapEllipsM3";
import MapC from "../components/Map/Map";
import MapM2 from "../components/Map/MapM2";
import StatСancels from "../components/Map/StatCancel";
import StatMasa from "../components/Map/StatMasa.js";
import StatUSer from "../components/Map/StatUser.js";
import Tabbar from "../components/Tabbar";
import logo from "../images/logo.png";
import { ref, remove, onValue } from "firebase/database";
import { getDatabase, set, get } from "firebase/database";
import { database } from "../firebaseConfig"; // Firebase initialized here

const { Content } = Layout;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

function Mapmain() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userLevel, setUserLevel] = useState('');
    const [open, setopen] = useState(false);
    const [isModalopen, setIsModalopen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedReason, setSelectedReason] = useState(''); // Define selectedReason state
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [permissions, setPermissions] = useState({});
    const [onlineCount, setOnlineCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null); // State for the selected row

    useEffect(() => {
        // Reference the Firebase Realtime Database
        const sessionsRef = ref(database, "sessions"); // Path to the 'sessions' node

        // Listen for changes in the 'sessions' node
        const unsubscribe = onValue(sessionsRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();

                // Flatten all sessions into an array
                const allSessions = Object.values(data).flatMap((session) =>
                    typeof session === "object" ? Object.values(session) : []
                );

                // Calculate counts based on `isOnline` status
                const onlineSessions = allSessions.filter((session) => session.isOnline === true).length;
                const offlineSessions = allSessions.filter((session) => session.isOnline === false).length;

                // Update state with counts
                setOnlineCount(onlineSessions);
                setOfflineCount(offlineSessions);
            } else {
                // Reset counts if no data exists
                setOnlineCount(0);
                setOfflineCount(0);
            }
        });

        // Cleanup listener on component unmount
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        // Fetch permissions from session storage
        const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
        console.log('Stored Permissions:', storedPermissions);

        if (storedPermissions && storedPermissions.length > 0) {
            setPermissions(storedPermissions[0]);
        }

        // Check if GuidesAzAdd permission is 0 and navigate if true
        if (storedPermissions && storedPermissions[0].Rezerv === 0) {
            navigate("/");
        }
    }, []);
    useEffect(() => {
        fetchData();
        fetchDatafirabase();
        const intervalId = setInterval(fetchData, 2000);
        return () => clearInterval(intervalId);
    }, []);

    const onClose = () => {
        setopen(false);
    };

    const fetchData = async () => {
        try {
            const phpResponse = await axios.get(`${CONN_KEY}app123/printrezervs.php`);
            console.log("PHP API Response:", phpResponse.data);

            const phpReservations = phpResponse.data.reservations || [];

            if (phpReservations.length === 0) {
                console.warn("No reservations returned from PHP API.");
                setData([]); // Set an empty array if no reservations
                setIsLoading(false);
                return;
            }

            // Filter for today's reservations (optional, based on your requirements)
            setData(phpReservations); // Directly set data from PHP API
            console.log("Set Table Data:", phpReservations); // Log the data being set
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching PHP data:", error);
            setIsLoading(false);
        }
    };

    const fetchDatafirabase = async () => {
        try {
            const getCurrentDateInBaku = () => {
                const now = new Date();
                const offset = 4 * 60 * 60 * 1000; // UTC+4
                const bakuTime = new Date(now.getTime() + offset);
                return bakuTime.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            };

            const currentDate = getCurrentDateInBaku();

            const phpResponse = await axios.get(`${CONN_KEY}app123/printrezervs.php`);
            const phpReservations = phpResponse.data.reservations || [];
            console.log("PHP Reservations:", phpReservations);

            // Filter PHP reservations for the current day
            const filteredPHPReservations = phpReservations.filter((reservation) => {
                const selectedDay = new Date(reservation.selectedDay).toISOString().split("T")[0];
                return selectedDay === currentDate;
            });

            console.log(`Filtered PHP Reservations for ${currentDate}:`, filteredPHPReservations);

            const db = getDatabase();
            filteredPHPReservations.forEach((reservation) => {
                const { Masa, selectedDay, selectedTime, selectedFlourId, note, userId, status } = reservation;

                let reservationRef;
                if (selectedFlourId.startsWith("L") || selectedFlourId.startsWith("B") || selectedFlourId.startsWith("R")) {
                    reservationRef = ref(db, `reservationsL2/${Masa}`);
                } else if (selectedFlourId.startsWith("M")) {
                    reservationRef = ref(db, `reservationsMM/${Masa}`);
                } else {
                    console.warn(`Unrecognized selectedFlourId ${selectedFlourId}. Skipping Masa ${Masa}.`);
                    return;
                }

                console.log("Syncing to Firebase:", {
                    Masa,
                    selectedDay,
                    selectedTime,
                    selectedFlourId,
                    note,
                    userId: String(userId),
                    status,
                });

                set(reservationRef, {
                    Masa,
                    selectedDay,
                    selectedTime,
                    selectedFlourId,
                    note,
                    userId: String(userId),
                    status,
                })
                    .then(() => {
                        console.log(`Successfully updated Masa ${Masa} in Firebase.`);
                    })
                    .catch((error) => {
                        console.error(`Error updating Masa ${Masa} in Firebase:`, error.message);
                    });
            });

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error.message);
            setIsLoading(false);
        }
    };

    const sendToFirebase = async () => {
        if (!selectedRow) {
            alert("No row selected! Please select a row first.");
            return;
        }

        const db = database;

        // Destructure `selectedRow` to get the required properties
        const { Masa, selectedFlourId } = selectedRow;

        // Determine the Firebase reference dynamically
        let reservationRef;
        if (selectedFlourId.startsWith("L") || selectedFlourId.startsWith("B") || selectedFlourId.startsWith("R")) {
            reservationRef = ref(db, `reservationsL2/${Masa}`);
        } else if (selectedFlourId.startsWith("M")) {
            reservationRef = ref(db, `reservationsMM/${Masa}`);
        } else {
            console.warn(`Unrecognized selectedFlourId ${selectedFlourId}. Skipping Masa ${Masa}.`);
            return;
        }

        try {
            // Send the selected row data to the determined Firebase reference
            await set(reservationRef, selectedRow);
            console.log("Selected reservation sent to Firebase:", selectedRow);
            alert("Selected reservation sent successfully!");
        } catch (error) {
            console.error("Error sending data to Firebase:", error);
            alert("Failed to send data to Firebase.");
        }
    };

    const isNotTodayOrAfterEndDay = (record) => {
        const today = new Date();
        const date = new Date(record.created_at);

        // Assuming endDay is a property in the record
        const endDay = record.endDay;

        return today.toDateString() !== date.toDateString() || (endDay && today > new Date(endDay));
    };
    useEffect(() => {
        // Fetch user level from local storage
        const storedUserLevel = localStorage.getItem('s');
        if (storedUserLevel) {
            setUserLevel(storedUserLevel);
        }
    }, []);
    const rowClassName = (record) => {
        const isDisabled = isNotTodayOrAfterEndDay(record);

        return isDisabled ? "grayscale disabled" : "";
    };
    const navigate = useNavigate();

    const getCurrentDateInBaku = () => {
        const now = new Date();
        const offset = 4 * 60 * 60 * 1000; // UTC+4
        const bakuTime = new Date(now.getTime() + offset);
        return bakuTime.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const isNotToday = (dateString) => {
        const currentDate = getCurrentDateInBaku();
        const targetDate = new Date(dateString).toISOString().split("T")[0];
        return currentDate !== targetDate;
    };

    const handlePermisson = (record) => {
        if (record.status === "cancelled") {
            console.log("This reservation is already cancelled. The modal won't be opened.");
            return; // Do not open the modal if the status is "cancelled"
        }

        setSelectedRecord(record);
        setIsModalopen(true);
        setSelectedReason(''); // Reset selectedReason when opening the modal
    };

    const handleModalSubmit = async (sebeb) => {
        try {
            // Retrieve the username from local storage
            const username = localStorage.getItem('username');

            // Define the API endpoint
            const endpoint = `${CONN_KEY}app123/permission.php`;

            // Create the request data object, including the selected record ID, reason, and username
            const requestData = {
                reservationId: selectedRecord.reservationId,
                userId: selectedRecord.userId,
                sebeb: sebeb,
                maneger: username, // Corrected to match the expected parameter name
            };

            // Send the POST request using axios
            const response = await axios.post(endpoint, requestData);

            // Log the API response
            console.log("API Response:", response.data);

            // If the reservation cancellation is successful, delete the corresponding reservation from Firebase
            if (response.data.message === "Reservation cancellation successful.") {
                deleteFirebaseReservation(selectedRecord.Masa, selectedRecord.selectedFlourId);
            }

            // Close the modal and fetch updated data
            setIsModalOpen(false); // Close the modal
            fetchData(); // Fetch updated data
        } catch (error) {
            console.error("Error in handleModalSubmit:", error);
            // Handle error appropriately, e.g., show an error message to the user
        }
    };

    // Function to delete a reservation from Firebase (either reservationsL2 or reservationsMM)
    const deleteFirebaseReservation = (masa, selectedFlourId) => {
        let reservationRef;

        // Check if the selectedFlourId corresponds to reservationsL2 or reservationsMM
        if (selectedFlourId.startsWith("L")) {
            // If selectedFlourId starts with 'L', delete from reservationsL2
            reservationRef = ref(database, `reservationsL2/${masa}`);
        } else if (selectedFlourId.startsWith("M")) {
            // If selectedFlourId starts with 'M', delete from reservationsMM
            reservationRef = ref(database, `reservationsMM/${masa}`);
        } else {
            console.error("Unknown selectedFlourId type: ", selectedFlourId);
            return;
        }

        // Remove the reservation from the appropriate path in Firebase
        remove(reservationRef)
            .then(() => {
                console.log(`Reservation ${masa}_${selectedFlourId} deleted successfully from Firebase.`);
            })
            .catch((error) => {
                console.error("Error deleting reservation from Firebase:", error);
            });
    };
    const canSeeSectionsRezervCancel = permissions.RezervCancel === 1;

    const renderActions = (text, record) => {

        if (
            record &&
            record.key
        ) {
            const menu = (
                <Menu>
                    {canSeeSectionsRezervCancel && (
                        <Menu.Item key="Permission" onClick={() => handlePermisson(record)}>Permission</Menu.Item>
                    )}

                    {/* <Menu.Item key="Details" onClick={() => showDrawer(record)}>
                        Details
                    </Menu.Item> */}
                </Menu>
            );

            return (
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button>
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            );
        }

        return (
            <Button>
                <MoreOutlined />
            </Button>
        );
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndices) => {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndices.join(', ')}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndices)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndices)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) => {
                return dataIndices.some((dataIndex) => {
                    const columnValue = record[dataIndex];
                    return columnValue
                        ? columnValue.toString().toLowerCase().includes(value.toLowerCase())
                        : false;
                });
            },
            onFilterDropdownOpenChange: (open) => {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            render: (text, record) => (
                dataIndices.map((dataIndex) => {
                    const textToHighlight = record[dataIndex]?.toString() || '';
                    return searchedColumn === dataIndex ? (
                        <Highlighter
                            key={dataIndex}
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={textToHighlight}
                        />
                    ) : (
                        <span>{textToHighlight}</span>
                    );
                })
            ),
        };
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: ['username', 'surename'],
            key: 'name',
            fixed: 'left',
            width: 150,
            ...getColumnSearchProps(['username', 'surename']),
            render: (text, record) => (
                <a href={`/PDF/${record.userid}`}>
                    <p>{record.username}  {record.surename}</p>
                </a>
            ),
        },
        {
            title: 'Masa',
            dataIndex: 'Masa',
            key: 'Masa',
            ...getColumnSearchProps(['Masa']),
        },
        {
            title: 'Mərtəbə Ərazi',
            dataIndex: 'selectedFlourId',
            key: 'selectedFlourId',
            ...getColumnSearchProps(['selectedFlourId']),
            width: 100,
        },
        {
            title: 'R Tarix / Saat',
            dataIndex: 'selectedDay',
            key: 'selectedDay',
            width: 100,
            render: (text, record) => {
                const date = new Date(text);
                return (
                    <div>
                        <p>{date.toLocaleDateString('en-GB')} {record.selectedTime}</p>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text) => (
                <Tag color={text === "pending" ? "yellow" : text === "complete" ? "green" : "red"}>
                    {text}
                </Tag>
            ),
        },
        {
            title: 'İmtina Səbəbi',
            dataIndex: 'sebeb',
            key: 'sebeb',
            width: 110,
        },
        {
            title: 'Maneger',
            dataIndex: 'Maneger',
            key: 'Manager',
            width: 68,
        },
        {
            title: 'Not',
            dataIndex: 'note',
            key: 'note',
            width: 100,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            width: 110,
            ...getColumnSearchProps(['created_at']),
            key: '75654',
            render: (text) => {
                const date = new Date(text);
                return date.toLocaleString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
            },
        },
        {
            title: "More",
            dataIndex: "userid",
            key: "256321",
            render: (text, record) => (
                <Dropdown overlay={
                    <Menu>
                        <Menu.Item key="Permission" onClick={() => handlePermisson(record)}>Permission</Menu.Item>
                    </Menu>
                }>
                    <Button><MoreOutlined /></Button>
                </Dropdown>
            ),
            width: 50,
            fixed: 'right',
        },
    ];

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams((prevParams) => ({
            ...prevParams,
            pagination,
        }));
    };
    const handlePrintExtra = () => {
        // Open a new window
        const popupWindow = window.open('/printpage', '_blank', 'width=1200,height=900');

        // Check if the element with id 'printpage' exists
        const printPageElement = document.getElementById('printpage');
        if (printPageElement) {
            // Create a div for the printed content
            const printContainer = document.createElement('div');

            // Clone the content of the 'printpage' element
            printContainer.innerHTML = printPageElement.innerHTML;

            // Add the date information
            const printDateDiv = document.createElement('div');
            printDateDiv.innerHTML = `<p style="text-align: center; bottom:10px;">Printed on: ${new Date().toLocaleString()} By Marketing | VERSİON: 1.0.1</p>`;
            printContainer.appendChild(printDateDiv);

            // Replace 'printContents' with 'originalContents'
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContainer.innerHTML;

            // Print the new content
            popupWindow.print();

            // Restore the original content
            document.body.innerHTML = originalContents;

            // Close the popup window
            popupWindow.document.close();
        }
    };
    const handlePrintEllips = () => {
        // Open a new window
        const popupWindow = window.open('/printpageEllips', '_blank', 'width=1200,height=900');

        // Check if the element with id 'printpage' exists
        const printPageElement = document.getElementById('printpage');
        if (printPageElement) {
            // Create a div for the printed content
            const printContainer = document.createElement('div');

            // Clone the content of the 'printpage' element
            printContainer.innerHTML = printPageElement.innerHTML;

            // Add the date information
            const printDateDiv = document.createElement('div');
            printDateDiv.innerHTML = `<p style="text-align: center; bottom:10px;">Printed on: ${new Date().toLocaleString()} By Marketing | VERSİON: 1.0.1</p>`;
            printContainer.appendChild(printDateDiv);

            // Replace 'printContents' with 'originalContents'
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContainer.innerHTML;

            // Print the new content
            popupWindow.print();

            // Restore the original content
            document.body.innerHTML = originalContents;

            // Close the popup window
            popupWindow.document.close();
        }
    };
    const fetchDataInComponents = () => {
        window.location.reload();
    };

    return (
        <Layout >
            <Header className="inset-x-0 top-0 z-50 bg-[#000910] flex flex-row flex-nowrap justify-between items-center">
                <Link to={"/Main"}>
                    <img
                        className="h-8 w-auto cursor-pointer"
                        src={logo}
                        alt="logo"
                    />
                </Link>
                <div className="flex items-center gap-5">
                    <Button className="bg-blue-50 p-2" onClick={fetchDataInComponents}>
                        <SyncOutlined spin />
                    </Button>
                    <Button
                        className="bg-blue-50 p-2"
                        onClick={sendToFirebase}
                    >
                        red
                    </Button>
                    <Button className="bg-blue-50 p-2" >
                        <a href={`/3d`}> 3D</a>
                    </Button>
                    <Button className="buttonprint bg-blue-50" target="_blank" onClick={handlePrintExtra}>
                        Print Extra
                    </Button>
                    <Button className="buttonprint bg-blue-50" target="_blank" onClick={handlePrintEllips}>
                        Print Ellips
                    </Button>
                    <Headers />
                </div>
            </Header>
            <Content className="site-layout ">
                <div style={{ padding: 24, minHeight: 380, background: colorBgContainer }}>
                    <div className='infosds'>
                        <div className='ckdj'>
                            <div className="redbox" />-
                            <p style={{ fontSize: '16px' }}>Rezerv edilib</p>
                        </div>
                        <div className='ckdj'>
                            <div className="greenbox" />-
                            <p style={{ fontSize: '16px' }}>Imtina edilib</p>
                        </div>
                        <div className='ckdj'>
                            <div className="greenboxBorder" />-
                            <p style={{ fontSize: '16px' }}>Boş Masalar</p>
                        </div>
                        <StatUSer />
                        <StatСancels />
                        <div className="session-counts">
                            <h3>Session Counts</h3>
                            <p>Online Sessions: {onlineCount}</p>
                            <p>Offline Sessions: {offlineCount}</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'row', gap: '10px' }}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            loading={isLoading}
                            rowKey="id"
                            rowSelection={{
                                type: 'radio', // Allows selecting only one row
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedRow(selectedRows[0]); // Update selected row
                                },
                            }}
                            pagination={{ pageSize: 5 }}
                        />
                    </div>
                    <Tabbar />
                    <Drawer
                        title="Basic Drawer"
                        placement="right"
                        onClose={onClose}
                        open={open}
                    >
                    </Drawer>
                    <Modal
                        title="Permission"
                        open={isModalopen}
                        onOk={() => handleModalSubmit(selectedReason)} // Remove 'rejected' argument
                        onCancel={() => setIsModalopen(false)}
                        destroyOnClose
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        okText="İmtina et"
                        cancelText="Cancel"
                    >
                        <p>İstifadəçiyə ləğvetmə icazəsi vermək istədiyinizə əminsiniz <b>{selectedRecord?.username} {selectedRecord?.surename} {selectedRecord?.Masa}</b>?</p>
                        <Input
                            type="text"
                            value={selectedReason}
                            rules={[
                                {
                                    required: true,
                                    message: "Zəhmət olmasa imtina səbəbini qeyd edin!",
                                },
                            ]}
                            placeholder="İmtina səbəbini qeyd edin"
                            onChange={(e) => setSelectedReason(e.target.value)}
                        />
                    </Modal>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            gap: '10px'
                        }}>
                        {/* <AutoSizer>
                            {({ height, width }) => (
                                <ReactSVGPanZoom
                                    width={width}
                                    height={height}
                                    ref={viewerRef}
                                    tool={tool}
                                    onChangeTool={setTool}
                                    value={value}
                                    onChangeValue={setValue}
                                    background="#FFF"
                                    detectAutoPan={false}
                                > */}
                        <MapC />
                        <MapM2 />
                        {/* <MapM1ellips />
                        <MapM2ellips />
                        <MapM3ellips /> */}
                        {/* </ReactSVGPanZoom>
                            )}
                        </AutoSizer> */}
                    </div>
                    <StatMasa />
                </div>
            </Content>
            <Footers />
        </Layout>
    );
};
export default Mapmain;