import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDuEDmeiUTLiQRQBNVLKmjz85CnbSUgz3Q",
    authDomain: "senatetest-1yh5o6.firebaseapp.com",
    databaseURL: "https://senatetest-1yh5o6-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "senatetest-1yh5o6",
    storageBucket: "senatetest-1yh5o6.appspot.com",
    messagingSenderId: "106697676461",
    appId: "1:106697676461:web:d34e725410b0f998225824"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const database = getDatabase(app);

// Initialize Firestore
const firestore = getFirestore(app); // Initialize Firestore

export { app, database, firestore }; // Export Firestore
