import { Button, Input } from "antd";
import "../Maaş.css";
import React, { useState } from "react";

function MaasHesaplama() {
  const [total, setTotal] = useState("");
  const [balans, setBalans] = useState("");
  const [kart, setKart] = useState("");
  const [qaz, setQaz] = useState("");
  const [benzin, setBenzin] = useState("");
  const [bonus, setBonus] = useState("");
  const [cerime, setCerime] = useState("");
  const [maasSonuc, setMaasSonuc] = useState("");
  const [benzinDegeri, setBenzinDegeri] = useState("");
  const [karDegeri, setKarDegeri] = useState("");
  const [kalankar, setkalankar] = useState("");
  const [hesaplamaSecimi, setHesaplamaSecimi] = useState("maas"); // Varsayılan olarak maaş hesaplama seçili

  const hesaplaMaas = () => {
    // Total'ı yukarı doğru yuvarla ve ikiye böl
    const yuvarlanmisTotal = Math.ceil(total);
    const yariTotal = yuvarlanmisTotal * 0.5;

    // Kart ve balansı topla
    const kartBalansToplami = kart + balans;

    // Maaşı hesapla
    const maaş = kartBalansToplami - yariTotal + bonus - cerime;
    const maaaş = kartBalansToplami - yariTotal - cerime;

    // Benzin değerini hesapla
    const benzinDegeri = yariTotal - cerime - maaaş;

    const kalankar = yariTotal - bonus;

    // State'i güncelle
    setkalankar(kalankar);
    setKarDegeri(yariTotal);
    setMaasSonuc(maaş);
    setBenzinDegeri(benzinDegeri);
  };

  const hesaplaMaasQaz = () => {
    // Total'ı yuvarla ve maaşı hesapla (Total'ın %30'u)
    const yuvarlanmisTotal = Math.round(total);
    const maaş = yuvarlanmisTotal * 0.3;

    // Kalan miktarı hesapla (Total'ın %70'i)
    const kalan = yuvarlanmisTotal * 0.7;

    // Bonusu maaşa ekleyerek maaşı hesapla
    const maaşSonuc = maaş + bonus - cerime;

    // Kalanı kara ekle

    const kar = kalan - benzin - qaz - bonus;

    // State'i güncelle
    setMaasSonuc(maaşSonuc);
    setKarDegeri(kar);
  };

  // Tüm verileri sıfırlama işlemi
  const sifirlaVerileri = () => {
    setTotal(0);
    setKart(0);
    setBalans(0);
    setCerime(0);
    setMaasSonuc(0);
    setBenzinDegeri(0);
    setKarDegeri(0);
    setBonus(0);
    setQaz(0);
    setBenzin(0);
  };

  return (
    <div className="containergdf">
      <h1>Hesabat </h1>
      <br />
      <label>
        <input
          type="radio"
          name="hesaplamaSecimi"
          value="maas"
          checked={hesaplamaSecimi === "maas"}
          onChange={() => setHesaplamaSecimi("maas")}
        />
        50%
      </label>
      <label>
        <input
          type="radio"
          name="hesaplamaSecimi"
          value="qaz"
          checked={hesaplamaSecimi === "qaz"}
          onChange={() => setHesaplamaSecimi("qaz")}
        />
        30%
      </label>
      <br />
      <br />
      <div>
        <label>Total: </label>
        <Input
          type="number"
          value={total}
          onChange={(e) => setTotal(Number(e.target.value))}
        />
      </div>
      {hesaplamaSecimi === "maas" && (
        <>
          <div>
            <label>Balans: </label>
            <Input
              type="number"
              value={balans}
              onChange={(e) => setBalans(Number(e.target.value))}
            />
          </div>
          <div>
            <label>Kart: </label>
            <Input
              type="number"
              value={kart}
              onChange={(e) => setKart(Number(e.target.value))}
            />
          </div>
        </>
      )}
      {hesaplamaSecimi === "qaz" && (
        <>
          <div>
            <label>Qaz: </label>
            <Input
              type="number"
              value={qaz}
              onChange={(e) => setQaz(Number(e.target.value))}
            />
          </div>
          <div>
            <label>Benzin: </label>
            <Input
              type="number"
              value={benzin}
              onChange={(e) => setBenzin(Number(e.target.value))}
            />
          </div>
        </>
      )}
      <div>
        <label>Bonus: </label>
        <Input
          type="number"
          value={bonus}
          onChange={(e) => setBonus(Number(e.target.value))}
        />
      </div>
      <div>
        <label>Cerime: </label>
        <Input
          type="number"
          value={cerime}
          onChange={(e) => setCerime(Number(e.target.value))}
        />
      </div>{" "}
      <br /> <br />
      <div style={{ display: "flex", gap: 10 }}>
        {" "}
        <Button
          onClick={hesaplamaSecimi === "maas" ? hesaplaMaas : hesaplaMaasQaz}
        >
          {hesaplamaSecimi === "maas" ? "Hesapla" : "Hesapla.."}
        </Button>
        <Button onClick={sifirlaVerileri}>Sıfırla</Button>
      </div>
      <br />
      <div>
        <div style={{ display: "flex" }}>
          <label>Maaş: </label>
          <span className="spangfgf">{maasSonuc}</span>
        </div>
        {hesaplamaSecimi === "maas" && (
          <>
            <div style={{ display: "flex" }}>
              <label>Benzin/Cərimə: </label>
              <span className="spangfgf">{benzinDegeri}</span>
            </div>
            <div style={{ display: "flex" }}>
              <label>Qazanc:</label>
              <span className="spangfgf">{kalankar}</span>
            </div>
          </>
        )}
        {hesaplamaSecimi === "qaz" && (
          <div style={{ display: "flex" }}>
            <label>Qazanc:</label>
            <span className="spangfgf">{karDegeri}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default MaasHesaplama;
