import { Skeleton, Button, Space } from 'antd';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import { Link } from 'react-router-dom';
import { FilePdfOutlined, IdcardOutlined } from '@ant-design/icons';

const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;

function WorkerC() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const invoiceSectionRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fakturaUrl = `${CONN_KEY_SENAT}Staff.php?id=${id}`;
                const fakturaResponse = await axios.get(fakturaUrl);
                setData(fakturaResponse.data);

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const handlePrint = () => {
        if (invoiceSectionRef.current) {
            const printContents = invoiceSectionRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            window.onload = () => {
                window.print();
                document.body.innerHTML = originalContents;
            };
        }
    };

    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space className="user-ids">
                        <Button className="ColorMain" onClick={handlePrint}>
                            Print
                        </Button>
                        <Link to={`/PDFStaff/${data.id}`} target='_blank'>
                            <Button icon={<FilePdfOutlined />}>
                                PDF
                            </Button>
                        </Link>
                    </Space>
                    <div id="invoice-section" ref={invoiceSectionRef}>
                        <div className="pages">
                            <div className="CarVisit">
                                <div className="ihdц">
                                    <div className="useridW">
                                        <h3 className="isd" style={{ color: '#010101' }}>
                                            VN={data.userid}
                                        </h3>
                                    </div>
                                    <div className="ojdkdeW">
                                        <div className="invoicxWE">
                                            <img
                                                className="imgPPW light-effect"
                                                src={`${CONN_KEY_SENAT}Staff/3x4Staff/${data.filePP}`}
                                                alt={data.filePP}
                                            />
                                        </div>
                                        <div className="hsaddfaWE">
                                            <div className="Namecart">
                                                <h2 className="fontsad" style={{ fontSize: 22 }}>
                                                    {data.username}
                                                </h2>
                                            </div>
                                            <div className="Namecartd3">
                                                <h2
                                                    className="fontsad"
                                                    style={{ fontSize: 'clamp(14px, 15px, 100%)' }}
                                                >
                                                    {data.surename}
                                                </h2>
                                            </div>
                                            <h2 className="jhasdfd">{data.vezife}</h2>
                                        </div>
                                        <div className="jhasdfda">
                                            <div>
                                                <p style={{ fontSize: 18, padding: 6 }}><span>{data.Departament}</span> Team</p>
                                            </div>
                                        </div>
                                        <h2 className="jhasd">{data.Catagory}</h2>
                                    </div>
                                    <div className="qr-nameWR">
                                        <img
                                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${CONN_KEY_SENAT}Personal/${data.userid}.pdf`}
                                            alt={`QR code for ${data.userid}`}
                                            className="qrcartdWR"
                                        // style={{color:;}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default WorkerC;
