import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Modal, Button, Upload, Card, Row, InputNumber, Select, Space, Drawer } from 'antd';
import { FieldNumberOutlined, PlusOutlined } from "@ant-design/icons";
import Webcamcomp from "./CameraComp";
import Uplodedimages from "./uploadedimages";
// import ImgCrop from 'antd-img-crop';
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const BedciGE = () => {
  const [inputs, setInputs] = useState({}); // Move this line here
  const [brands, setBrands] = useState([]);
  const [maxUserId, setMaxUserId] = useState(0);
  const [oneuserid, setuserione] = useState(0);
  const [openCam, setOpenCam] = useState(false);

  const showDrawercam = () => {
    setOpenCam(true);
  };

  const onCloseCam = () => {
    setOpenCam(false);
  };
  useEffect(() => {
    // Fetch guide data from AddGE.php
    axios
      .get(CONN_KEY + 'AddGE.php')
      .then((response) => {
        setBrands(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  axios.get(`${CONN_KEY}AddGE.php?userid=${inputs.userid}`);
  // Now you can access 'inputs' variable here
  useEffect(() => {
    axios
      .get(CONN_KEY + 'AddGE.php')
      .then((response) => {
        const data = response.data;
        // Find the maximum userid value from the response data
        const maxId = Math.max(...data.map((item) => item.userid));
        // Add +1 to maxId and set it
        const useridone = (maxId + 1).toString().padStart(3, '0');
        setMaxUserId(maxId);
        setBrands(data);
        setuserione(useridone);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log('Received values of form: ', values);

      const userIdString = values.userid.toString();
      const formattedUserId = userIdString.length <= 3
        ? userIdString.padStart(3, '0')
        : userIdString.slice(0, 6);

      const formData = new FormData();
      formData.append("userid", formattedUserId);
      formData.append('username', values.username);
      formData.append('surename', values.surename);
      formData.append('dadname', values.dadname);
      formData.append('Beletcinum', values.Beletcinum);
      formData.append('email', values.email);
      formData.append('Fin', values.Fin);
      formData.append('datebhrd', values.datebhrd);
      formData.append('Country', values.Country);
      formData.append('Phone', values.Phone);
      formData.append('vezife', values.vezife);
      formData.append('filePP', values.filePP?.[0]?.originFileObj);
      formData.append('fileSH', values.fileSH?.[0]?.originFileObj);

      console.log('formData:', formData);

      const response = await axios.post(`${CONN_KEY}AddGE.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === 1) {
        message.success('guide added successfully');
        window.location.href = `/CartGE/${formattedUserId}`;
        form.resetFields();
      } else {
        message.error('You have now installed this user. Or the inputs are clear.');
      }
    } catch (error) {
      console.log(error);
      message.error('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    message.error('Suitable areas cannot remain empty!');
  };

  const handleChange = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onGenderChange = (value) => {
    console.log('Selected Gender:', value);
  };

  const fileList = [];
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleCancel = () => setPreviewOpen(false);
  
  return (
    <div className="container">
      {contextHolder}
      <h1>Add New Guide</h1>
      <Form
        onFinish={onFinish}
        encType="multipart/form-data" // Add this attribute
        className="form-Add container"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinishFailed={onFinishFailed}
      >
        <Card extra={
          <Space>
            <Button className="camera-drawer" type="default" onClick={showDrawercam}>
              Camera
            </Button>
          </Space>
        }>
          <div style={{ gap: "10" }}>
            <Row gutter={[16, 16]}>
              <Form.Item
                className="ant-form-item"
                label="Vn №"
                name="userid"
                rules={[
                  {
                    required: true,
                    message: "Please select a license number!",
                  },
                ]}
                extra={`V/N to be recorded ${oneuserid.toString()}`}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  name="userid"
                  onChange={(value) => handleChange("userid", value)}
                  placeholder={`V/N to be recorded ${oneuserid.toString()}}`}
                  min={1}
                  formatter={(value) => {
                    const paddedValue = value.toString().padStart(3, '0');
                    return paddedValue === '0' ? '000' : paddedValue;
                  }}
                  parser={(value) => value.replace(/\D/g, "")}
                  step={1}
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="surname"
                name="surename"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Surname',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="surename"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="username"
                  size="large"
                  onChange={handleChange}
                  placeholder="Ad"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Father's name"
                name="dadname"
              >
                <Input
                  style={{ width: '100%' }}
                  name="dadname"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Personal NO"
                name="Fin"
                rules={[
                  {
                    required: true,
                    message: "Please enter Fin code",
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="Fin"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Day of birth"
                name="datebhrd"
              >
                <Input
                  style={{ width: '100%' }}
                  name="datebhrd"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Country"
                name="Country"
              >
                <Input
                  style={{ width: '100%' }}
                  name="Country"
                  size="large"
                  onChange={handleChange}
                  placeholder="Georgia"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Phone number"
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="Phone"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                label="Position"
                name="vezife"
                size="large"
                rules={[
                  {
                    required: true,
                    message: 'Please select a position',
                  },
                ]}
              >
                <Select
                  placeholder="Select a position"
                  onChange={onGenderChange}
                  allowClear size="large"
                  name="vezife"
                >
                  <Option value="Tour Guide">Tour Guide</Option>
                  <Option value="Driver">Driver</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className='ant-form-item'
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter a guiden",
                  },
                ]}
                shouldUpdate={(prevValues, currentValues) => prevValues.vezife !== currentValues.vezife}
              >
                {({ getFieldValue }) =>
                  getFieldValue('vezife') === 'Driver' ? (
                    <Form.Item
                      className='ant-form-item' name="Beletcinum" label="Guide">
                      <Select
                        name="Beletcinum"
                        showSearch
                        filterOption={(input, option) => {
                          const label = (option?.label ?? '').toLowerCase();
                          const inputValue = input.toLowerCase();
                          const userId = (option?.value ?? '').toLowerCase();
                          const userName = option?.props.children?.[0]?.toLowerCase() ?? '';

                          return label.includes(inputValue) || userId.includes(inputValue) || userName.includes(inputValue);
                        }}
                        size="large"
                        placeholder="Guide"
                        onChange={handleChange}
                      >
                        {brands.map((brand) => (
                          <Option key={brand.userid} value={brand.userid} label={`${brand.username} ${brand.surename}`}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span>{brand.username} {brand.surename}</span>
                              <span><FieldNumberOutlined />{brand.userid}</span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="3x4"
                name="filePP"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa 3x4 rəsimini seçin",
                  },
                ]}
                getValueFromEvent={(e) => e.fileList}
              >
                <Upload name="filePP" maxCount={1} listType="picture-card" onPreview={handlePreview}>
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Şəxsiyyət vəsiqəsi"
                name="fileSH"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa Ş.V rəsimini seçin",
                  },
                ]}
              >
                <Upload name="fileSH" maxCount={1} listType="picture-card" onPreview={handlePreview}>
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Row>
            <Space>
              <Button htmlType="reset">Reset</Button>
              <Button htmlType="submit" type="primary" className="ColorMain" >
                Əlavə Et
              </Button>
            </Space>
          </div>
        </Card>
      </Form>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onCloseCam}
        open={openCam}
      >
        <Webcamcomp />
        <Uplodedimages />
      </Drawer>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default BedciGE;
