import React, { useState, useEffect } from 'react';
import { Modal, Form, Checkbox, Select, Button, message, Row, Col, Spin } from 'antd';
import axios from 'axios';
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const { Option } = Select;

const permissionsList = [
  'Admin',
  'GuidesAz',
  'GuidesAzAdd',
  'GuidesAzEdit',
  'GuidesAzBlock',
  'GuidesAzUnblock',
  'GuidesAzBlockApp',
  'GuidesAzDelete',
  'GuidesGeo',
  'GuidesGeoAdd',
  'GuidesGeoEdit',
  'GuidesGeoBlock',
  'GuidesGeoUnblock',
  'GuidesGeoDelete',
  'GuidesAzBalance',
  'StafAz',
  'StafAzAdd',
  'StafAzEdit',
  'StafAzDelet',
  'StafAzOutWork',
  'StafGeo',
  'StafGeoAdd',
  'StafGeoEdit',
  'StafGeoOutWork',
  'Menuaz',
  'MenuazAdd',
  'MenuazEdit',
  'MenuazDelete',
  'MenuPdfAZ',
  'MenuGeo',
  'MenuGeoAdd',
  'MenuGeoEdit',
  'MenuGeoDelete',
  'Abone',
  'AboneAdd',
  'AboneEdit',
  'AboneDelet',
  'AboneBlock',
  'AboneUnblock',
  'Rezerv',
  'RezervCancel',
];

const PermissionsModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [updatingPermissions, setUpdatingPermissions] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchUsers();
    }
  }, [visible]);

  const fetchUsers = async () => {
    try {
      setLoadingUsers(true);
      const response = await axios.get(`${CONN_KEY}user.php`);
      if (response.status === 200) {
        setUsers(response.data || []);
      } else {
        message.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error fetching users');
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedPermissions(permissionsList);
    } else {
      setSelectedPermissions([]);
    }
  };

  const handlePermissionChange = (permission, checked) => {
    const updatedPermissions = checked
      ? [...selectedPermissions, permission]
      : selectedPermissions.filter((p) => p !== permission);

    setSelectedPermissions(updatedPermissions);
    setSelectAll(updatedPermissions.length === permissionsList.length);
  };

  const handleSubmit = async () => {
    if (!selectedUser) {
      message.error('No user selected');
      return;
    }

    const permissions = {};
    permissionsList.forEach(permission => {
      permissions[permission] = selectedPermissions.includes(permission) ? 1 : 0;
    });

    const payload = {
      userId: selectedUser,
      permissions,
    };

    console.log('Payload:', JSON.stringify(payload, null, 2)); // Log payload for debugging

    try {
      setUpdatingPermissions(true);
      const response = await axios.post(`${CONN_KEY}userpermissionupdate.php`, payload, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200 || response.status === 201) {
        message.success('Permissions updated successfully');
        form.resetFields();
        setSelectedPermissions([]);
        setSelectedUser(null);
        setSelectAll(false);
        onCancel(); // Close modal after successful update
      } else {
        message.error('Failed to update permissions');
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
      if (error.response) {
        message.error(`Error updating permissions: ${error.response.data.message}`);
      } else if (error.request) {
        message.error('Error updating permissions: No response from server');
      } else {
        message.error(`Error updating permissions: ${error.message}`);
      }
    } finally {
      setUpdatingPermissions(false);
    }
  };

  return (
    <Modal
      title="Manage Permissions"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Spin spinning={loadingUsers || updatingPermissions}>
        <Form form={form} layout="vertical">
          <Form.Item label="Users">
            <Select
              value={selectedUser}
              onChange={(value) => setSelectedUser(value)}
              placeholder="Select user"
              style={{ width: '100%' }}
              loading={loadingUsers}
              disabled={loadingUsers}
            >
              {users.map(user => (
                <Option key={user.id} value={user.id}>
                  {user.username}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="permissions" label="Permissions">
            <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
              Select All
            </Checkbox>
            <Row>
              {permissionsList.map(permission => (
                <Col span={8} key={permission}>
                  <Checkbox
                    checked={selectedPermissions.includes(permission)}
                    onChange={(e) => handlePermissionChange(permission, e.target.checked)}
                    disabled={!selectedUser}
                  >
                    {permission}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} loading={updatingPermissions}>
              Update Permissions
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PermissionsModal;
