import { Layout } from 'antd';
import { VERSİON } from "../Version";
const { Footer } = Layout;
const Footers = () => {
    const email = "marketing@senategroup.az"; // Replace with your email address
    const email2 = "office@senategroup.az"; // Replace with your email address

    return (
        <Layout className='dark:bg-slate-900'>
            <Footer className="login-form-footer dark:bg-slate-900">
                <div>
                    By Marketing | Contact: <a href={`mailto:${email}`}>{email}</a> or <a href={`mailto:${email2}`}>{email2}</a>
                </div>
                <div className='logdsfdsh'>
                    VERSİON: {VERSİON}
                </div>
            </Footer>
        </Layout>
    );
};

export default Footers;
