import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TableGeo from '../components/TableGE';
const { Content } = Layout;

const TableGe = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 10px',
                        // height: '80vh'
                    }}
                >
                    <div
                        style={{
                            padding: 4,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <h1>Guides /Drivers</h1>
                        <br /><br />
                        <TableGeo />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};

export default TableGe;
