import { Skeleton, Button, Space, message } from 'antd';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import Block from '../images/block.png';
import { Link } from 'react-router-dom';
import { FilePdfOutlined, IdcardOutlined } from '@ant-design/icons';

import '../App.css';
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
axios.interceptors.request.use((config) => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Headers'] = '*';
    config.headers['Access-Control-Allow-Methods'] = '*';
    return config;
});

function StaffPdfGe() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const invoiceSectionRef = useRef(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fakturaUrl = `${CONN_KEY}StaffGE.php?id=${id}`;
                // console.log('fakturaUrl:', fakturaUrl); // Check the URL in the console
                const fakturaResponse = await axios.get(fakturaUrl);
                // console.log('fakturaResponse:', fakturaResponse.data); // Check the response data in the console
                setData(fakturaResponse.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);
    const handlePrint = () => {
        const invoiceSection = document.getElementById("invoice-section");
        if (invoiceSection) {
            const printContents = invoiceSection.innerHTML;
            const originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;
            window.print();

            const restoreOriginalContent = () => {
                // After printing is done, restore the original content
                document.body.innerHTML = originalContents;

                // Display a message
                message.info("Refreshing the page...");

                // Reload the component after a short delay (adjust the delay as needed)
                setTimeout(() => {
                    window.location.reload();
                }, 1000); // Refresh after 1 second (1000 milliseconds)
            };

            if (window.matchMedia) {
                const mediaQueryList = window.matchMedia("print");
                mediaQueryList.addListener((mql) => {
                    if (!mql.matches) {
                        // Printing has finished
                        restoreOriginalContent();
                    }
                });
            } else {
                // Fallback for browsers that don't support matchMedia
                window.onafterprint = restoreOriginalContent;
            }
        }
    };

    const handleDownload = async () => {
        if (invoiceSectionRef.current) {
          const printContents = invoiceSectionRef.current.innerHTML;
          const originalContents = document.body.innerHTML; // Define originalContents here
      
          const printContainer = document.createElement("div");
          printContainer.innerHTML = printContents;
      
          // Append the print container to the body
          document.body.appendChild(printContainer);
      
          const images = printContainer.getElementsByTagName("img");
      
          const loadImagePromises = Array.from(images).map(async (image) => {
            try {
              const imageUrl = `https://cors-anywhere.herokuapp.com/${image.src}`; // Add the CORS proxy URL
              const response = await axios.get(imageUrl, { responseType: "blob" });
              const blob = response.data;
              const reader = new FileReader();
      
              const base64Promise = new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
              });
      
              reader.readAsDataURL(blob);
              const base64DataUrl = await base64Promise;
              image.src = base64DataUrl;
            } catch (error) {
              console.error("Error downloading image:", error);
            }
          });
      
          try {
            await Promise.all(loadImagePromises);
          } catch (error) {
            console.error("Error loading images:", error);
            document.body.innerHTML = originalContents;
            return;
          }
      
          const opt = {
            margin: 0,
            padding: 0,
            filename: `${data.userid}.pdf`,
            image: { type: "jpeg", quality: 0.90 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: "mm", format: [305, 216] }, // Use the same dimensions as the printed content
            // Add custom CSS to remove borders and set padding to 0
            css: 'img { border: none; } body { padding: 0; }',
          };
      
          html2pdf()
            .set(opt)
            .from(invoiceSectionRef.current)
            .toPdf()
            .output("blob")
            .then((pdfBlob) => {
              // Create form data and append the PDF file
              const formData = new FormData();
              formData.append("pdf", pdfBlob, `${data.userid}.pdf`);
      
              // Send the form data via POST request to the server
              axios
                .post(`${CONN_KEY}OutoPdfStafdGe.php`, formData)
                .then((response) => {
                  // Handle the response from the server
                  console.log("Upload successful:", response);
      
                  // Display success message
                  message.success("PDF uploaded successfully!");
      
                  // Remove the temporary printContainer
                  document.body.removeChild(printContainer);
      
                  message.info("Refreshing the page..."); // Display a message
      
                  // Reload the component after a short delay (adjust the delay as needed)
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000); // Refresh after 1 second (1000 milliseconds)
                })
                .catch((error) => {
                  console.error("Error uploading PDF:", error);
                  document.body.innerHTML = originalContents;
                  // Display error message
                  message.error("Error uploading PDF. Please try again.");
                });
            })
            .catch((error) => {
              console.error("Error generating PDF:", error);
              document.body.innerHTML = originalContents;
              // Display error message
              message.error("Error generating PDF. Please try again.");
            });
        }
      };
      

    // console.log('data:', data); // Check the data in the console
    // console.log("isLoading:", isLoading); // Check the isLoading state in the console
    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space>
                        <Button onClick={handlePrint}>Print</Button>
                        <Button onClick={handleDownload}>Download</Button>
                        <Link to={`/CardPersonal-Ge/${data.id}`} target='_blank'>
                            <Button icon={<IdcardOutlined />}>
                                Card
                            </Button>
                        </Link>
                    </Space>
                    <div className='container' ref={invoiceSectionRef}>
                        <div id="invoice-section">
                            {data.Status === 'Block' && <img className='Block' src={Block} alt='block' />}
                            <div className="jjhgdfstaffgeu pageStaffge ">
                                <div className="hjhfm">
                                    <div className="ttextsd">
                                        <h3 className="stsff namessd" style={{ fontSize: "26px" }}>
                                            {data.username} {data.surename} <br />
                                            {data.dadname}
                                        </h3><br />
                                        <h3 className="stsff ssaStaf" style={{ color: "#0E79FE" }}>{data.vezife}</h3>
                                        {data.Beletcinum !== undefined && data.Beletcinum !== "undefined" && (
                                            <h3 className="stsff ssda">Voucher No: {data.Beletcinum}</h3>
                                        )}
                                        <h3 className="stsff">Fin: {data.Fin}</h3>
                                        <h3 className="stsff">Date of birth: {data.datebhrd}</h3>
                                        <h3 className="stsff">Nationality: {data.Country}</h3>
                                        <h3 className="stsff">Contact: {data.Phone}</h3>
                                        <h3 className="stsff">Voucher No.: {data.userid}.pdf</h3>
                                        <h3 className="stsff">Date of Recruitment: {data.recruitment}</h3>
                                        <h3 className="stsff">Date of resignation: <span style={{ color: 'red' }}>{data.outdate}</span></h3>
                                        <h3 className="stsff">Reason for Termination: <span style={{ color: 'red' }}>{data.outwhy}</span> </h3>
                                        <h3 className="stsff">Education: {data.edu}</h3>
                                        <h3 className="stsff">The address: {data.lokationLive} </h3>
                                        <h3 className="stsff">Registration address: {data.lokatiogeyd}</h3>
                                    </div>
                                    <div className='sgsd'>
                                        <div className="jshdf">
                                            <h3 className="hwfd">{data.Departament} Team</h3>
                                            <img
                                                className="imgPPDF light-effect"
                                                src={`${CONN_KEY}Data/api/StaffGe/3x4Staff/${data.filePP}`}
                                                alt="PP"
                                            />
                                        </div>
                                        <div>
                                            <h3 className="hw3hf">{data.Catagory}</h3>
                                            <h3 className='djgljc'>Category and<br /> Degree of accreditation</h3>
                                            <h3 className='djgljc'><span className='fohsd'>{data.lokationW}</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="fdjdx">
                                    <img
                                        className="imgLKSH"
                                        src={`${CONN_KEY}Data/api/StaffGe/scanStaff/${data.fileSH}`}
                                        alt="SH"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default StaffPdfGe;