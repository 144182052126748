import { Skeleton, Button, Space, message } from "antd";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { FilePdfOutlined, IdcardOutlined } from "@ant-design/icons";
import html2pdf from "html2pdf.js";
import Block from "../images/block.png";
import "../App.css";

axios.interceptors.request.use((config) => {
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Headers"] = "*";
  config.headers["Access-Control-Allow-Methods"] = "*";
  return config;
});

function Fakturas() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { UserId } = useParams();
  const invoiceSectionRef = useRef(null);
  const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fakturaUrl = `${CONN_KEY}AddGE.php?userid=${UserId}`;
        // console.log('fakturaUrl:', fakturaUrl);
        const fakturaResponse = await axios.get(fakturaUrl);
        // console.log('fakturaResponse:', fakturaResponse.data);

        const beletcinum = fakturaResponse.data.Beletcinum; // Get the Beletcinum value
        const userDataUrl = `${CONN_KEY}AddGE.php?userid=${beletcinum}`; // Fetch user data based on Beletcinum
        const userDataResponse = await axios.get(userDataUrl);
        // console.log('userDataResponse:', userDataResponse.data);

        const beletcinumUsername = userDataResponse.data.username;
        const beletcinumsurename = userDataResponse.data.surename;
        setData({
          ...fakturaResponse.data,
          beletcinum: beletcinum,
          beletcinumUsername: beletcinumUsername,
          beletcinumsurename: beletcinumsurename,
        });

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [UserId]);

  const handlePrint = () => {
    if (invoiceSectionRef.current) {
      const printContents = invoiceSectionRef.current.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.onload = () => {
        window.print();
        document.body.innerHTML = originalContents;
      };
      message.info("Refreshing the page..."); // Display a message

      // Reload the component after a short delay (adjust the delay as needed)
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Refresh after 1 second (1000 milliseconds)
    }
  };

  const handleDownload = async () => {
    if (invoiceSectionRef.current) {
      const printContents = invoiceSectionRef.current.innerHTML;
      const originalContents = document.body.innerHTML; // Define originalContents here

      const printContainer = document.createElement("div");
      printContainer.innerHTML = printContents;

      // Append the print container to the body
      document.body.appendChild(printContainer);

      const images = printContainer.getElementsByTagName("img");

      const loadImagePromises = Array.from(images).map(async (image) => {
        try {
          const imageUrl = `https://cors-anywhere.herokuapp.com/${image.src}`; // Add the CORS proxy URL
          const response = await axios.get(imageUrl, { responseType: "blob" });
          const blob = response.data;
          const reader = new FileReader();

          const base64Promise = new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
          });

          reader.readAsDataURL(blob);
          const base64DataUrl = await base64Promise;
          image.src = base64DataUrl;
        } catch (error) {
          console.error("Error downloading image:", error);
        }
      });

      try {
        await Promise.all(loadImagePromises);
      } catch (error) {
        console.error("Error loading images:", error);
        document.body.innerHTML = originalContents;
        return;
      }

      const opt = {
        margin: 0,
        filename: `${data.userid}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: [299.5, 211], orientation: "portrait" }, // Use the same dimensions as the printed content
      };

      html2pdf()
        .set(opt)
        .from(invoiceSectionRef.current)
        .toPdf()
        .output("blob")
        .then((pdfBlob) => {
          // Create form data and append the PDF file
          const formData = new FormData();
          formData.append("pdf", pdfBlob, `${data.userid}.pdf`);

          // Send the form data via POST request to the server
          axios
            .post(`${CONN_KEY}OutoPdfGE.php`, formData)
            .then((response) => {
              // Handle the response from the server
              console.log("Upload successful:", response);

              // Display success message
              message.success("PDF uploaded successfully!");

              // Remove the temporary printContainer
              document.body.removeChild(printContainer);

              message.info("Refreshing the page..."); // Display a message

              // Reload the component after a short delay (adjust the delay as needed)
              setTimeout(() => {
                window.location.reload();
              }, 1000); // Refresh after 1 second (1000 milliseconds)
            })
            .catch((error) => {
              console.error("Error uploading PDF:", error);
              document.body.innerHTML = originalContents;
              // Display error message
              message.error("Error uploading PDF. Please try again.");
            });
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          document.body.innerHTML = originalContents;
          // Display error message
          message.error("Error generating PDF. Please try again.");
        });
    }
  };

  // console.log('data:', data); // Check the data in the console
  // console.log("isLoading:", isLoading); // Check the isLoading state in the console

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Space>
            <Button className="ColorMain" onClick={handleDownload}>
              Download
            </Button>
            <Button onClick={handlePrint}>Print</Button>
            <Link to={`/CartGE/${data.userid}`} target="_blank">
              <Button icon={<IdcardOutlined />}>Card</Button>
            </Link>
          </Space>
          <div className="container kks">
            <div id="invoice-section" ref={invoiceSectionRef}>
              {data.Status === "Block" && (
                <img className="Block" src={Block} alt="block" />
              )}
              <div className="geupdf pagegeu">
                <div className="hjhfm">
                  <div className="ttextsd">
                    <h3 className="hw3e namessd">
                      {data.username} {data.surename} <br />
                      {data.dadname}
                    </h3>
                    <h3 className="hw3e ssda">{data.vezife}</h3>
                    {data.vezife !== "Tour Guide" &&
                      data.Beletcinum !== undefined &&
                      data.Beletcinum !== "undefined" && (
                        <>
                          <h3 className="hw3e ssda">
                            Vəsiqə №: {data.Beletcinum}
                          </h3>
                          <h3 className="hw3e ssda">
                            {data.beletcinumUsername} {data.beletcinumsurename}
                          </h3>
                        </>
                      )}
                    <h3 className="hw3e">Fin: {data.Fin}</h3>
                    <h3 className="hw3e">Doğum tarixi: {data.datebhrd}</h3>
                    <h3 className="hw3e">Vətəndaşlığ: {data.Country}</h3>
                    <h3 className="hw3e">Əlaqə: {data.Phone}</h3>
                    <h3 className="hw3e">Vəsiqə №: {data.userid}.pdf</h3>
                    <h3 className="hw3e">Block Səbəbi: {data.BlockSeb}</h3>
                    <h3 className="hw3e">Block Tarixi: {data.BlockSebdate}</h3>
                  </div>
                  <div className="jshdf">
                    <img
                      className="imgPPDF"
                      src={`${CONN_KEY}Data/api/3x4GE/${data.filePP}`}
                      alt="PP"
                    />
                  </div>
                </div>
                <div className="fdjdx">
                  <img
                    className="imgLKSH"
                    src={`${CONN_KEY}Data/api/scanGE/${data.fileSH}`}
                    alt="SH"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Fakturas;
