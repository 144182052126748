import { Skeleton, List, Pagination, Button, Input, message, Space, Select } from 'antd';
import axios from 'axios';
import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import '../App.css';
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
function AllCardsGeo() {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectSearchQuery, setSelectSearchQuery] = useState('');
    const invoiceSectionRef = useRef(null);
    const pageSize = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fakturaUrl = `${CONN_KEY}StaffGE.php?id=${id}`;
                const fakturaResponse = await axios.get(fakturaUrl);
                setData(fakturaResponse.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const handlePrint = () => {
        if (invoiceSectionRef.current) {
            const printContents = invoiceSectionRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            window.onload = () => {
                window.print();
                document.body.innerHTML = originalContents;
            };
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };

    const handleSearchc = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSelectSearch = (selectedValues) => {
        setSelectSearchQuery(selectedValues);

        if (selectedValues.length === 0) {
            setFilteredItems([]); // Reset filtered items when nothing is selected
            return;
        }

        const filteredData = data.filter((item) =>
            selectedValues.some((val) => item.userid.includes(val))
        );

        setCurrentPage(1);
        setFilteredItems(filteredData);
    };

    // Get the data for the current page
    const filteredData = searchQuery ? data.filter((item) => item.userid.includes(searchQuery)) : data;

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredItems.length > 0 ? filteredItems.slice(startIndex, endIndex) : filteredData.slice(startIndex, endIndex);
    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space className="user-ids">
                        <Button className="ColorMain" onClick={handlePrint}>
                            Print
                        </Button>
                        <Select
                            mode="multiple"
                            placeholder="Search userid"
                            value={selectSearchQuery} // Use the new state here
                            onChange={handleSelectSearch} // Use the new function here
                            style={{ width: 200 }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {data.map((item) => (
                                <Option key={item.userid} value={item.userid}>
                                    {item.userid}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                    <div className="container">
                        <div id="invoice-section" ref={invoiceSectionRef} className='pagejsd'>
                            <div className="pages-geo-all">
                                <List>
                                    <div className="products">
                                        {paginatedData.map((item, index) => (
                                            <div
                                                className="CarVisitALL"
                                                key={`${item.userid}-${index}`}
                                            // id="invoice-section"
                                            >
                                                <div className="ihdцGE">
                                                    <div className="useridW">
                                                        <h3 className="isd" style={{ color: '#010101' }}>
                                                            VN={item.userid}
                                                        </h3>
                                                    </div>
                                                    <div className="ojdkdeW">
                                                        <div className="invoicxWE">
                                                            <img
                                                                className="imgPPW light-effect"
                                                                src={`${CONN_KEY}StaffGe/3x4Staff/${item.filePP}`}
                                                                alt={item.filePP}
                                                            />
                                                        </div>
                                                        <div className="hsaddfaWE">
                                                            <div className="Namecart">
                                                                <h2 className="fontsad" style={{ fontSize: 22 }}>
                                                                    {item.username}
                                                                </h2>
                                                            </div>
                                                            <div className="Namecartd3">
                                                                <h2
                                                                    className="fontsad"
                                                                    style={{ fontSize: 'clamp(14px, 15px, 100%)' }}
                                                                >
                                                                    {item.surename}
                                                                </h2>
                                                            </div>
                                                            <h2 className="jhasdfd">{item.vezife}</h2>
                                                        </div>
                                                        <div className="jhasdfda">
                                                            <div>
                                                                <p style={{ fontSize: 18, padding: 6 }}><span>{item.Departament}</span> Team</p>
                                                            </div>
                                                        </div>
                                                        <h2 className="jhasd">{item.Catagory}</h2>
                                                    </div>
                                                    <div className="qr-nameWR">
                                                        <img
                                                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${CONN_KEY}Personal-Ge/${item.id}.pdf`}
                                                            alt={`QR code for ${item.id}`}
                                                            className="qrcartdWR"
                                                        // style={{color:;}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </List>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={filteredData.length}
                        onChange={handleChangePage}
                    />
                </>
            )}
        </>
    );
}

export default AllCardsGeo;
