import React, { useState } from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import axios from 'axios';
const Settingsapp = () => {
    const CONN_KEY_R = process.env.REACT_APP_NOT_SECRET_CODE_RR;
    const [selectedSettingName, setSelectedSettingName] = useState(null);
    const [selectedSettingValue, setSelectedSettingValue] = useState(null);

    const updateDatabase = async (values) => {
        try {
            // Update the state with the selected setting name and value
            setSelectedSettingName(values.settingName);
            setSelectedSettingValue(values.settingValue);

            // Send the request to update the database with the selected values
            const response = await axios.put(`${CONN_KEY_R}Settingsupdate.php`, {
                SettingName: values.settingName,
                SettingValue: values.settingValue,
            });

            console.log(response.data);

            if (response.data.status === 1) {
                message.success(response.data.message);
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            // handle error
        }
    };

    const settingOptions = [
        {
            value: 'RezervStart',
            label: 'Rezervin başlama vaxdı',
        },
        {
            value: 'RezervEnd',
            label: 'Rezervin Bitmə vaxdı',
        },
        // {
        //     value: 'endDay',
        //     label: 'Rezervi sıfırlanma vaxdı',
        // },
        // {
        //     value: 'Version',
        //     label: 'Versia',
        // },
    ];
    const ValueOption = [
        { value: '00', label: '00:00' },
        { value: '01', label: '01:00' },
        { value: '02', label: '02:00' },
        { value: '03', label: '03:00' },
        { value: '04', label: '04:00' },
        { value: '05', label: '05:00' },
        { value: '06', label: '06:00' },
        { value: '07', label: '07:00' },
        { value: '08', label: '08:00' },
        { value: '09', label: '09:00' },
        { value: '10', label: '10:00' },
        { value: '11', label: '11:00' },
        { value: '12', label: '12:00' },
        { value: '13', label: '13:00' },
        { value: '14', label: '14:00' },
        { value: '15', label: '15:00' },
        { value: '16', label: '16:00' },
        { value: '17', label: '17:00' },
        { value: '18', label: '18:00' },
        { value: '19', label: '19:00' },
        { value: '20', label: '20:00' },
        { value: '21', label: '21:00' },
        { value: '22', label: '22:00' },
        { value: '23', label: '23:00' },
        { value: '24', label: '24:00' },
    ];


    return (
        <>
            <h3>Update App Time</h3>
            <Form
                name="Update Settings"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={updateDatabase}  // Use updateDatabase for onFinish
            >
                <Form.Item
                    className='ant-form-item'
                    name="settingName"  // Ensure this matches the key in values object
                    rules={[
                        {
                            required: true,
                            message: 'Please select a settingName!',
                        },
                    ]}
                    style={{ minWidth: "191px" }}
                >
                    <Select
                        placeholder="Setting"
                        optionFilterProp="children"
                        options={settingOptions}
                    />
                </Form.Item>
                {/* settingValue */}
                <Form.Item
                    className='ant-form-item'
                    name="settingValue"  // Ensure this matches the key in values object
                    rules={[
                        {
                            required: true,
                            message: 'Please select a value!',
                        },
                    ]}
                    style={{ minWidth: "191px" }}
                >
                    <Select
                        placeholder="Setting"
                        optionFilterProp="children"
                        options={ValueOption}
                    />
                </Form.Item>

                <Form.Item className='ant-form-item'>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Yenilə
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default Settingsapp;
