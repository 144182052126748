import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

const StatUSer = () => {
  // State to hold the top 5 reserved users data
  const [topReservedUsersData, setTopReservedUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  const fetchTopReservedUsersData = async () => {
    try {
      const response = await axios.get(`${CONN_KEY}StatUserRezerv.php`);

      // Log the API response to check its structure and content
      console.log("API response:", response.data);
      
      // Check if the response data is an array
      if (Array.isArray(response.data)) {
        setTopReservedUsersData(response.data);
      } else {
        console.error("Unexpected response structure:", response.data);
        setError("Unexpected response structure.");
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopReservedUsersData();
  }, []);

  // Render the data
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  if (topReservedUsersData.length > 0) {
    return (
      <div className="dashboard">
        <Card bordered={false} className="cardStat">
          <h3>Top 5 Most Reserved Users</h3>
          <Avatar.Group shape="square">
            {topReservedUsersData.map((user) => (
              // Use Tooltip to display the username and surname when hovering over the avatar
              <Tooltip key={user.userId} title={`${user.username} ${user.surename} ${user.reservationCount}`}>
                <Avatar
                  src={`${CONN_KEY}3x4/${user.userImageURL}`}
                  icon={!user.userImageURL ? <UserOutlined /> : null}
                />
              </Tooltip>
            ))}
          </Avatar.Group>
        </Card>
      </div>
    );
  }

  return <div>No data found</div>;
};

export default StatUSer;
