import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import { useCookies } from 'react-cookie';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; import WorkerC from '../components/CardWorck';
;

const { Content } = Layout;
const Cardw = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 50px',
                    }}
                >
                    <div
                       style={{
                            padding: 4,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <WorkerC />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};
export default Cardw;